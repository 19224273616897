import PropTypes from "prop-types";
import React from 'react';
import { StyledAlert } from '../../shared/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AccessDenied = ({overrideText}) => (
  <StyledAlert danger>
    <FontAwesomeIcon icon='ban' /> {overrideText ? overrideText: 'Access Denied'} 
  </StyledAlert>
);

AccessDenied.propTypes = {
  overrideText: PropTypes.string
};

export default AccessDenied;