export const SET_SELECTED_CARD = 'SET_SELECTED_CARD';

export const ADD_CARD = 'ADD_CARD';
export const ADD_CARD_ERROR = 'ADD_CARD_ERROR';
export const ADD_CARD_SUCCESS = 'ADD_CARD_SUCCESS';

export const DELETE_CARD = 'DELETE_CARD';
export const DELETE_CARD_ERROR = 'DELETE_CARD_ERROR';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';

export const INVITE_TO_PAY = 'INVITE_TO_PAY';
export const INVITE_TO_PAY_ERROR = 'INVITE_TO_PAY_ERROR';
export const INVITE_TO_PAY_SUCCESS = 'INVITE_TO_PAY_SUCCESS';

export const JOURNAL_CHARGE = 'JOURNAL_CHARGE';
export const JOURNAL_CHARGE_ERROR = 'JOURNAL_CHARGE_ERROR';
export const JOURNAL_CHARGE_SUCCESS = 'JOURNAL_CHARGE_SUCCESS';

export const PRE_AUTH_SALES_ORDER = 'PRE_AUTH_SALES_ORDER';
export const PRE_AUTH_SALES_ORDER_ERROR = 'PRE_AUTH_SALES_ORDER_ERROR';
export const PRE_AUTH_SALES_ORDER_SUCCESS = 'PRE_AUTH_SALES_ORDER_SUCCESS';

export const PRE_AUTH_SERVICE_ORDER = 'PRE_AUTH_SERVICE_ORDER';
export const PRE_AUTH_SERVICE_ORDER_ERROR = 'PRE_AUTH_SERVICE_ORDER_ERROR';
export const PRE_AUTH_SERVICE_ORDER_SUCCESS = 'PRE_AUTH_SERVICE_ORDER_SUCCESS';

export const GET_CARDS_BY_ACCOUNT_NUMBER = 'GET_CARDS_BY_ACCOUNT_NUMBER';
export const GET_CARDS_BY_ACCOUNT_NUMBER_ERROR = 'GET_CARDS_BY_ACCOUNT_NUMBER_ERROR';
export const GET_CARDS_BY_ACCOUNT_NUMBER_SUCCESS = 'GET_CARDS_BY_ACCOUNT_NUMBER_SUCCESS';

export const GET_CAN_SAVE_CREDIT_CARD = 'GET_CAN_SAVE_CREDIT_CARD';
export const GET_CAN_SAVE_CREDIT_CARD_ERROR = 'GET_CAN_SAVE_CREDIT_CARD_ERROR';
export const GET_CAN_SAVE_CREDIT_CARD_SUCCESS = 'GET_CAN_SAVE_CREDIT_CARD_SUCCESS'; 