import styled from 'styled-components';
import { ModalHeader } from '@met/react-components';

const StyledModalHeader = styled(ModalHeader)`
  & svg {
    font-size: 1.25em;
    margin-right: .5rem;
    width: 1.05em;
    height: 1.05em;
  }
`;

const StyledErrorMessage = styled.div`
  color: #db011c;
  display: flex;
  margin-bottom: 0;
  margin-top: 0.25rem;
  align-items: center;
  justify-content: center;

  & svg {
    margin-right: .25rem;
  }
`;

export {
  StyledModalHeader,
  StyledErrorMessage
};