import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import {toastr} from "react-redux-toastr";
import { isObjectWithKeys } from '../../helpers';
import { StyledHeaderH2 } from '../../shared/styled';
import TranCompleteModal from '../../components/TranComplete';
import { useSignalR, useParsedQueryString } from '../../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledGrid, StyledLeftCol, StyledRightCol, StyledButtonCol } from './styled';
import { terminalActions, transactionActions, configurationActions } from '../../store';
import { Button, Card, CardBody, CardHeader, Row, useToggle } from '@met/react-components';
import { TranDetails, RemainingBalanceModal, AmountsTable, SimulateCheckoutDialog } from './components';

const Checkout = (props) => {
  const { queryParams, isParsed } = useParsedQueryString();
  
  const [amountReceived, setAmountReceived] = useState(0);
  const [amountRemaining, setAmountRemaining] = useState(props.transaction.total || 0);
  const [showCompletedDialog, setShowCompletedDialog] = useState(false);
  const [showIncompleteDialog, setShowIncompleteDialog] = useState(false);
  const [simulateDialogHidden, toggleSimulateDialogHidden] = useToggle(true);
  const [hubConnection, isHubConnected] = useSignalR(props.signalRHubUrl, queryParams.terminalId);

  useEffect(() => {
    if (!props.appConfigIsLoaded && isParsed) {
      props.getAppConfig(queryParams.locationId); 
    }
  }, [queryParams, isParsed]);

  useEffect(() => {
    if (isParsed) {
      props.getTransaction(queryParams.transactionId);
      props.getCheckoutDetails(queryParams.transactionId);
    }
  }, [isParsed, queryParams]);

  useEffect(() => {
    if (isHubConnected) {
      hubConnection.on('RefreshTerminalCheckout', (message) => {
        props.getCheckoutDetails(message.TransactionId);
      });
    }
  }, [hubConnection, isHubConnected]);  

  useEffect(() => {    
    const getCheckoutAmounts = (tranTotal, checkouts) => {
      let received = 0;
      let total = tranTotal;
      if (checkouts) {
        for (let i = 0; i < checkouts.length; i++) {
          total -= checkouts[i].receivedAmount;
          received += checkouts[i].receivedAmount;
        }
      }
      return [total, received];
    };

    if (isObjectWithKeys(props.transaction)) {
      const [total, received] = getCheckoutAmounts(props.transaction.total, props.checkouts);
      setAmountRemaining(total);
      setAmountReceived(received);
    }
  }, [props.transaction, props.checkouts]);

  const simulateCheckout = useCallback((data) => {
    return props.simulateCheckout(data);
  }, []);
  
  const requestCheckout = useCallback(() => { 
    if (amountRemaining <= 0) {
      return new Promise((resolve) => resolve());
    }
    return props.requestAdditionalCheckout(queryParams.transactionId, amountRemaining, queryParams.terminalId).then(() => {
      props.getCheckoutDetails(queryParams.transactionId);
    });
  }, [queryParams, amountRemaining]);
  
  const verifyCheckout = () => {
    if (!props.isLoading) {
      props.verifyCheckout(queryParams.transactionId).then((data) => {      
        setAmountRemaining(data.data.amountRemaining);    
        if (data.data.amountRemaining === 0) 
          setShowCompletedDialog(true);   
        else 
          setShowIncompleteDialog(true);
      });
    }
  };
  const pollCheckout = ()=>{
    if(!props.isLoaded){
      props.pollCheckout(queryParams.transactionId).then(()=>{        
        props.getCheckoutDetails(queryParams.transactionId).then(()=>{
          toastr.success("Refreshing", "Refreshing latest checkout status");  
        })        
      }).catch(()=>{
        toastr.error("Error", "Unable to refresh checkout status");
      })
    }
  }

  // External trap only
  const handleCompleteModalClose = () => {
    setShowCompletedDialog(false);
  };
  
  return (
    <StyledGrid>
      <Row>
        <StyledHeaderH2>Checkout</StyledHeaderH2>
      </Row>
      <Row>
        <StyledLeftCol xs={12} md={8} isMobileDevice={props.isMobileDevice}>
          <Card>
            <CardHeader>Terminal Actions</CardHeader>
            <CardBody>
              <AmountsTable checkouts={props.checkouts} /> 
            </CardBody>
          </Card>    
        </StyledLeftCol>
        <StyledRightCol xs={12} md={4} isMobileDevice={props.isMobileDevice}>
          <Card>
            <CardHeader>Transaction Summary</CardHeader>
            <CardBody>
              <TranDetails 
                transaction={props.transaction}
                amountReceived={amountReceived}
                amountRemaining={amountRemaining}
              />
            </CardBody>
          </Card>
          <Row>
            <StyledButtonCol isMobileDevice={props.isMobileDevice}>
              { props.showRequestAdditionalCheckout && (
                  <Button 
                    onClick={requestCheckout} 
                    disabled={amountRemaining === 0} 
                    loading={props.isLoading && !showIncompleteDialog}
                  >
                    <FontAwesomeIcon icon='dollar-sign' /> Request Remaining
                  </Button>)
              }
              <Button primary onClick={verifyCheckout}>
                <FontAwesomeIcon icon='check' /> Verify Checkout
              </Button>
              { props.showPollCheckout && (
                <Button
                  onClick={pollCheckout}
                  loading={props.isLoading}
                >
                  <FontAwesomeIcon icon='sync' /> Refresh Checkout
                </Button>)
              }
              {
                props.allowTestCards && (
                  <Button secondary onClick={toggleSimulateDialogHidden}>Simulate Checkout</Button>
                )
              }
            </StyledButtonCol>  
          </Row>
        </StyledRightCol>
      </Row>
      <RemainingBalanceModal 
        isHidden={!showIncompleteDialog} 
        isLoading={props.isLoadingCheckout}
        toggle={setShowIncompleteDialog} 
        onRequestCheckout={requestCheckout} 
      />
      {
        props.allowTestCards && (
          <SimulateCheckoutDialog 
            hidden={simulateDialogHidden}
            toggle={toggleSimulateDialogHidden}
            postSimulation={simulateCheckout}
            isLoading={props.isSimulationLoading}
            checkoutAmount={amountRemaining}
            transactionId={queryParams.transactionId}
          />
        )
      }
      {showCompletedDialog && <TranCompleteModal onModalClose={handleCompleteModalClose} />}
    </StyledGrid>
  );
};

// Access to state
const mapStateToProps = state => {
  return {
    isLoading : state.terminal.checkoutDetails.isLoading || state.transaction.getTransaction.isLoading || state.terminal.additionalCheckout.isLoading || state.configuration.isLoading || state.terminal.pollCheckout.isLoading,
    isLoadingCheckout : state.terminal.checkoutDetails.isLoading || state.terminal.additionalCheckout.isLoading,
    checkouts: state.terminal.checkoutDetails.checkouts,
    transaction:  state.transaction.getTransaction.data,
    signalRHubUrl: state.configuration.data.signalRConfigureEndpoint,
    appConfigIsLoaded: state.configuration.isLoaded,
    isMobileDevice: state.configuration.isMobileDevice,
    isSimulationLoading: state.terminal.simulateCheckout.isLoading,
    allowTestCards: state.configuration.data.allowTestCards,
    showRequestAdditionalCheckout: state.configuration.data.showRequestAdditionalCheckout,
    showPollCheckout: state.configuration.data.showPollCheckout
  };
};

// What actions are exposed on the component
const mapDispatchToProps = dispatch => ({
  getAppConfig: (locationId) => dispatch(configurationActions.getAppConfiguration(locationId)),
  getCheckoutDetails: (transactionId) => dispatch(terminalActions.getCheckoutDetails(transactionId)),
  getTransaction: (transactionId) => dispatch(transactionActions.getTransaction(transactionId)),
  requestAdditionalCheckout: (transactionId, amount, terminalId) => dispatch(terminalActions.requestAdditionalCheckout(transactionId, amount, terminalId)),
  verifyCheckout: (transactionId) => dispatch(terminalActions.verifyCheckout(transactionId)),
  simulateCheckout: (data) => dispatch(terminalActions.simulateCheckout(data)),
  pollCheckout: (transactionId) => dispatch(terminalActions.pollCheckout(transactionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);

