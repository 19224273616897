import styled from 'styled-components';
import { Col } from '@met/react-components';
import { fadeIn } from '../../shared/styled';

const StyledWrapperCol = styled(Col)`
  opacity: 0;
  animation: ${fadeIn} ease-in-out .35s both;
`;

const TableWrapper = styled.div`
  table {
    table-layout: fixed;
    width: 100%;
    word-wrap: break-word;
  }

  td:nth-child(3),
  td:nth-child(4){

  }

  th:nth-child(1),
  td:nth-child(1),
  th:nth-child(2),
  td:nth-child(2),
  th:nth-child(5),
  td:nth-child(5)
  {
    width: 10%;
  }

  th:nth-child(4),
  td:nth-child(4)
  {
    width: 20%;
  }
`;

export {
  StyledWrapperCol,
  TableWrapper
};