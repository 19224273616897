import history from '../history';
import { getSession } from '../helpers';
import { msalInstance, loginRequest, isUsingEntraAuth } from '../msal';
import { AuthError } from '@azure/msal-browser';

const createFetchAction = (config) => async (dispatch, getState) => {
  dispatch({ ...config.actionPayload, type: config.startAction });
  let body = undefined;
  let headers = {
    Pragma: 'no-cache'
  };
  if (config.files) {
    let form = new FormData();
    for (var index = 0; index < config.files.length; index++) {
      var element = config.files[index];
      form.append(element.name, element.file);
    }
    form.append('model', JSON.stringify(config.body))
    body = form;
  } else {
    body = JSON.stringify(config.body);
    headers = {
      ...headers,
      'Content-Type': 'application/json'
    };
  }

  // On AX custom chrome browser or onprem trap, use jwt from token endpoint
  if (!isUsingEntraAuth) {
    let session = getSession();

    // if the user is authenticated, automatically attach their jwt to the request
    if (session) {
      headers = {
        ...headers,
        Authorization: `Bearer ${session.accessToken}`
      }
    }
  } else {
    // On web using Entra auth, use Entra auth token
    const accounts = msalInstance.getAllAccounts();

    const idToken =
      (
        await msalInstance
          .acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          })
          .catch((error) => {
            if (error instanceof AuthError) {
              // fallback to interaction when silent call fails
              return msalInstance.acquireTokenRedirect({
                ...loginRequest,
                account: accounts[0],
              });
            }
          })
      )?.idToken ?? "";

      headers = {
        ...headers,
        Authorization: `Bearer ${idToken}`
      }
  }

  return fetch(config.url, {
    method: config.method || 'GET',
    headers,
    body
  })
    .then(response => {
      // handle specific errors
      switch (response.status) {
        case 500:
          console.error('Some server error');
          break;
        case 404:
          if (!config.ignore404) {
            history.replace('/NotFound');
          }
          break;
        case 403:
        case 401:
        default:
          break;
      }

      // Only if the response is ok should we continue
      if (response.ok) {
        if (config.isBlob) {
          return response.blob();
        } else {
          return response.text().then(text => {
            return text ? JSON.parse(text) : {}
          });
        }
      } else {
        return Promise.reject(response);
      }
    })
    .then(payload => {
      dispatch({ ...config.actionPayload, type: config.startAction + '_SUCCESS', payload: payload });
      return payload;
    })
    .catch(error => {
      dispatch({ ...config.actionPayload, type: config.startAction + '_ERROR' });
      return Promise.reject(error);
    });
};

export default createFetchAction;