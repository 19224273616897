import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '@met/react-components';
import { FormattedNumber, FormattedDate } from 'react-intl';
import { numberFormatOptions } from '../../../../shared/constants';

const RefundedLineItem = ({ tenderRefunded, currencyCode }) => (
  <Row>
    <Col xs={2} s={2} md={1} xl={1}>
      &nbsp;
    </Col>
    <Col xs={3} s={3}>
      {tenderRefunded.status}
    </Col>
    <Col xs={4} s={4} md={3} xl={2}>
      <FormattedDate value={tenderRefunded.isCaptured ? tenderRefunded.updated : tenderRefunded.created} />
    </Col>
    <Col xs={3} s={3}>
      <FormattedNumber
        currency={currencyCode}
        {...numberFormatOptions}
        value={tenderRefunded.refundAmount / 100}
      />  
    </Col>
  </Row>
);

RefundedLineItem.propTypes = {
  tenderRefunded: PropTypes.object,
};

export default RefundedLineItem;