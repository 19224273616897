import React, {useEffect, useState} from 'react';
import AccessCodeModal from "../../components/AccessCodeModal";
import history from '../../history';
import { useDispatch, useSelector} from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { StyledWrapperCol } from './styled';
import {locationActions, terminalActions} from '../../store';
import { useParsedQueryString } from '../../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledGrid, StyledRow } from '../../shared/styled';
import { isArrayWithLength, getExternalIdKVP, getExternalIdTypeName } from '../../helpers';
import {
  Dropdown,
  Card,
  CardBody,
  CardHeader,
  InputGroup,
  InputGroupAppend,
  Button,
  Modal,
  Text,
  ModalHeader,
  ModalBody,
  ModalFooter,
  LoadingDots, 
  ModalContent
} from '@met/react-components';

const SendToTerminal = () => {
  const dispatch = useDispatch();  
  
  const terminal = useSelector(state => state.terminal);
  const isNewDeviceCodeLoading = useSelector(state=>state.locations.getDeviceCode.isLoading);
  const isNewDeviceCodeError = useSelector(state=>state.locations.getDeviceCode.isError);
  const deviceCode = useSelector(state=>state.locations.deviceCode);
  const isDeviceStatusLoading = useSelector(state=>state.locations.getDeviceStatus.isLoading);
  const isDeviceStatusError = useSelector(state=>state.locations.getDeviceStatus.isError);
  
  const { queryParams, isParsed } = useParsedQueryString();
  
  const [isDeviceNotPairedModalOpen, setIsDeviceNotPairedModalOpen] = useState(false);
  const [isNewCodeModalOpen, setIsNewCodeModelOpen] = useState(false);  
  const [isDevicePaired, setIsDevicePaired] = useState(false);

  const setSelectedTerminal = (terminal) =>{
    return dispatch(terminalActions.setSelectedTerminal(terminal));
  }
  
  useEffect(() => {
    if (isParsed) {
      dispatch(terminalActions.getTerminalsByLocationId(queryParams.locationId))
        .catch((e) => toastr.error('Error: Get Terminals', `Could not retrieve terminals for location: ${queryParams.locationId}.`));
    }
  }, [isParsed, queryParams]);
   
  const sendToTerminal = (deviceId) =>{
    const externalIds = getExternalIdKVP(queryParams);
    const { accountNumber, amount, currencyCode, locationId, type } = queryParams;
    const transactionType = getExternalIdTypeName(type);
    
    return dispatch(terminalActions.sendToTerminal(transactionType, accountNumber, amount, currencyCode, locationId, deviceId, externalIds))
      .then((data) => {
        history.push(`/Checkout?tr=${data.data.transactionId}&d=${deviceId}&l=${locationId}`);
      })
      .catch(() => {
        toastr.error('Error: Send To Terminal', `Could not checkout using terminal ${terminal.selectedTerminal.terminalName}.`);
      });
  }
  
  const handleSendToTerminalClick = () => {
    if (!terminal.selectedTerminal) {
      return;
    }
    
    if(terminal.selectedTerminal.deviceCodeId){      
      if(!terminal.selectedTerminal.deviceId){
        //device is not active, allow user to generate a new device code, or select a different device
        setIsDevicePaired(false);
        setIsDeviceNotPairedModalOpen(true);        
      } else {
        dispatch(locationActions.getDeviceStatus(terminal.selectedTerminal.id)).then(response => {
          if (response.data.status === "PAIRED") {
            sendToTerminal(response.data.device_id);
          } else {
            //device is not active, allow user to generate a new device code, or select a different device
            setIsDevicePaired(false);
            setIsDeviceNotPairedModalOpen(true);
          }
        }).catch((err)=>{
          if(err.status === 404){
            setIsDevicePaired(false);
            setIsDeviceNotPairedModalOpen(true);
          } else {
            toastr.error('Error: Getting device status', `Could not determine device status ${terminal.selectedTerminal.terminalName}.`);
          }
        });
      }
    } else {
      //assume the device is active and send it through
      sendToTerminal(terminal.selectedTerminal.terminalId);
    }
  };
  
  const checkDeviceStatusAndSend = (notPairedCallback)=>{
    dispatch(locationActions.getDeviceStatus(terminal.selectedTerminal.id)).then(response => {
      if (response.data.status === "PAIRED") {
        setIsDeviceNotPairedModalOpen(false);
        setIsNewCodeModelOpen(false);
        setIsDevicePaired(true);
        sendToTerminal(response.data.deviceId);
      } else if(notPairedCallback) {
        notPairedCallback(response.data);
      }
    })
    .catch((err)=>{
      if(err.status === 404) {
        notPairedCallback(err.data);
      }else {
        toastr.error('Error: Getting device status', `Could not determine device status ${terminal.selectedTerminal.terminalName}.`);
      }
    });
  }
  
  const useOtherDeviceClicked = ()=>{
    setIsDeviceNotPairedModalOpen(false);
  }
  
  const getNewDeviceCode = ()=>{
    setIsDeviceNotPairedModalOpen(false);
    setIsDevicePaired(false);
    setIsNewCodeModelOpen(true);
    dispatch(locationActions.getDeviceCode(terminal.selectedTerminal.id)).catch(()=>{
      toastr.error('Error: Getting Device Code', `Could not get device code.`);
    });
  }
  
  const handleNewCodeDialogClose = ()=>{
    checkDeviceStatusAndSend(d=>{
      setIsDevicePaired(false);
    });    
  }
  
  const checkDeviceStatus = ()=>{
    dispatch(locationActions.getDeviceStatus(terminal.selectedTerminal.id)).then((response)=>{
      if (response.data.status === "PAIRED") {
        setIsDevicePaired(true);
      } else {
        setIsDevicePaired(false);
      }
    })
  }
  
  return isArrayWithLength(terminal.terminals) && (
    <StyledWrapperCol>
      <Card>
        <CardHeader>Send To Terminal</CardHeader>
        <CardBody>
          <StyledGrid fluid>
            <StyledRow>
              <InputGroup>
                <Dropdown
                  options={terminal.terminals}
                  value={terminal.selectedTerminal}
                  placeholder={'Select Terminal..'}
                  getOptionValue={(terminal) => (terminal.terminalId)}
                  getOptionLabel={(terminal) => (terminal.terminalName)}
                  onChange={setSelectedTerminal}
                />
                <InputGroupAppend>
                  <Button 
                    secondary 
                    onClick={handleSendToTerminalClick}
                    disabled={!terminal.selectedTerminal} 
                    loading={terminal.sendToTerminal.isLoading} 
                  >
                    <FontAwesomeIcon icon='tablet-alt'/> Send
                  </Button>
                </InputGroupAppend>
              </InputGroup>
            </StyledRow>
          </StyledGrid>
        </CardBody>
      </Card>
      <Modal hidden={!isDeviceNotPairedModalOpen}>
        <ModalContent>
          <ModalHeader>
            Selected Device Not Ready
          </ModalHeader>
          <ModalBody>
            {isDeviceStatusLoading && <LoadingDots />}
            {!isDeviceStatusLoading && !isDeviceStatusError && isDevicePaired && <Text block success>Device Paired</Text> }
            {!isDeviceStatusLoading && !isDeviceStatusError && !isDevicePaired && <Text block warning>Device NOT Paired</Text> }
            {isDeviceStatusError && <Text block error>Error getting device status</Text> }
          </ModalBody>
          <ModalFooter>
            <Button onClick={checkDeviceStatus} loading={isDeviceStatusLoading}>Check Again</Button>
            <Button onClick={useOtherDeviceClicked} style={{marginLeft: '5px'}} primary>Use other Device</Button>          
            <Button onClick={getNewDeviceCode} style={{marginLeft: '5px'}} >Get Device Code</Button>          
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AccessCodeModal
        isLoading={isNewDeviceCodeLoading}
        isError={isNewDeviceCodeError}
        isOpen={isNewCodeModalOpen}
        accessCode={deviceCode.code} 
        handleOk={handleNewCodeDialogClose}
        checkDeviceStatus={checkDeviceStatus}
        isPaired={isDevicePaired}
        isStatusLoading={isDeviceStatusLoading}
        handleCancel={()=>setIsNewCodeModelOpen(false)}
        />
    </StyledWrapperCol>
  );
};

export default SendToTerminal;