import React from 'react';
import PropTypes from 'prop-types';
import { PageLoader as PageLoaderMET } from '@met/react-components';

const PageLoader = React.memo(({ loading }) => (
  !loading ? null : (
    <div style={{ margin: '1rem auto' }}>
      <PageLoaderMET />
    </div> 
  )
));

PageLoader.propTypes = {
  loading: PropTypes.bool,
};

export default PageLoader;