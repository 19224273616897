import * as types from './types';
import createFetchAction from '../createFetchAction';

export const actionCreators = {  
  getAppConfiguration: (locationId) => {
    return createFetchAction({
      method: 'GET',
      url: `/api/v1/config${locationId && `?locationId=${locationId}`}`,
      startAction: types.GET_CONFIGURATION
    });
  },
};