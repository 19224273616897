import * as types from './types';
import createFetchAction from '../createFetchAction';

export const actionCreators ={
  getSquareRequests: (externalIdType, externalId) => {
    return createFetchAction({
      method: 'GET',
      url: `/api/v1/square/requests/${externalIdType}/${externalId}`,
      startAction: types.GET_SQUARE_REQUESTS
    });
  }
};