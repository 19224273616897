import React from 'react';
import { FormattedNumber } from 'react-intl';
import { numberFormatOptions, defaultCurrencyCode } from '../../../../shared/constants';

export const amountsTableColumns = {
  checkoutAmount: {
    label: 'Requested',
    content: (item) => {
      return (
        <FormattedNumber
          {...numberFormatOptions}
          currency={item.currencyCode ? item.currencyCode : defaultCurrencyCode}
          value={item.checkoutAmount ? item.checkoutAmount / 100 : 0}
        />
      );
    }
  },
  received: {
    label: 'Received',
    content: (item) => {
      return ( 
        <FormattedNumber
          {...numberFormatOptions}
          currency={item.currencyCode ? item.currencyCode : defaultCurrencyCode}
          value={item.receivedAmount ? item.receivedAmount / 100 : 0}
        />
      );
    }
  },
  checkoutStatus: {
    label: 'Status'
  }
};
