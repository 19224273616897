import * as types from './types';
import createFetchAction from '../createFetchAction';

export const actionCreators = {
  setSelectedTerminal: (terminal) => {
    return {
      type: types.SET_SELECTED_TERMINAL, 
      selectedTerminal: terminal, 
    };
  },
  getTerminalsByLocationId: (locationId) => {
    return createFetchAction({
      method: 'GET',
      url: `/api/v1/location?locationId=${locationId}`,
      startAction: types.GET_TERMINALS_BY_LOCATION_ID 
    });
  },
  getCheckoutDetails: (transactionId) => {
    return createFetchAction({
      method: 'GET',
      url: `/api/v1/checkout/ByTransaction/${transactionId}`,
      startAction: types.GET_CHECKOUT_DETAILS
    })
  },
  sendToTerminal: (transactionType, accountNumber, amount, currencyCode, locationId, terminalId, externalIds) => {
    return createFetchAction({
      method: 'POST',
      url: '/api/v1/checkout',
      startAction: types.SEND_TO_TERMINAL,
      body: {
        transactionType,
        amount,
        locationId,
        terminalId,
        currencyCode,
        accountNumber,
        externalIds,
      }
    });
  },
  requestAdditionalCheckout: (transactionId, amount, terminalId) => {
    return createFetchAction({
      method: 'POST',
      url: '/api/v1/checkout/RequestAdditionalCheckout',
      startAction: types.REQUEST_ADDITIONAL_CHECKOUT,
      body:{
        transactionId,
        amount,
        terminalId
      }        
    });
  },
  verifyCheckout: (transactionId) => {
    return createFetchAction({
      method: 'GET',
      url: `/api/v1/checkout/VerifyTransaction/${transactionId}`,
      startAction: types.VERIFY_CHECKOUT      
    });
  },
  simulateCheckout: (simulate)=>{
    return createFetchAction({
      method: 'POST',
      url: '/api/v1/checkout/SimulateCheckout',
      startAction: types.SIMULATE_CHECKOUT,
      body:simulate
    });
  },
  pollCheckout: (transactionId) =>{
    return createFetchAction({
      method: 'GET',
      url: `/api/v1/checkout/PollLatestCheckout/${transactionId}`,
      startAction: types.POLL_CHECKOUT
    })
  }
};
