import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { testCardOptions } from './constants';
import { StyledModalHeader, StyledErrorMessage } from './styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useInput, Button, Col, Dropdown, InputGroup, Modal, ModalBody, ModalContent, ModalFooter, Row, TextInput } from '@met/react-components';

const TestCardDialog = React.memo(({ addTestCard, hidden, toggle, isLoading }) =>{
  const cardholderName = useInput('');
  const [cardNonce, setCardNonce] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (hidden) {
      setErrorMessage(null);
    }
  }, [hidden]);
  
  const handleAddClicked = () => {
    if (!cardholderName.hasValue || !cardNonce.value) {
      setErrorMessage('Please fill in all fields above');
    } else {
      addTestCard(cardNonce.value, cardholderName.value).then(() => toggle());
    }
  };

  const errorMessageElement = errorMessage && (
    <Row>
      <Col xs>
        <StyledErrorMessage>
          <FontAwesomeIcon icon='exclamation-circle' /> {errorMessage}
        </StyledErrorMessage>
      </Col>
    </Row>
  );
  
  return (
    <Modal centered hidden={hidden} toggle={toggle} >
      <ModalContent>
        <StyledModalHeader toggle={toggle}>
          <FontAwesomeIcon icon='credit-card' /> Add New Test Card
        </StyledModalHeader>
        <ModalBody style={{'minHeight': '300px'}}>
          <Row>
            <Col>
              <InputGroup>
                <TextInput
                  placeholder='Cardholder Name'
                  {...cardholderName.bindToInput}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup>
                <Dropdown
                  value={cardNonce}
                  placeholder={'Select Credit Card..'}
                  getOptionValue={(option) => (option.value)}
                  getOptionLabel={(option) => (option.label)}
                  onChange={(option) => setCardNonce(option)}
                  options={testCardOptions}
                />
              </InputGroup>
            </Col>
          </Row>
          {errorMessageElement}
        </ModalBody>
        <ModalFooter>
          <Button primary outline onClick={toggle}>Cancel</Button>
          <Button primary onClick={handleAddClicked} loading={isLoading}>Add Card</Button>          
        </ModalFooter>
      </ModalContent>
    </Modal>    
  );  
});

TestCardDialog.propTypes = {
  addTestCard: PropTypes.func,
  hidden: PropTypes.bool,
  isLoading: PropTypes.bool,
  toggle: PropTypes.any
};

export default TestCardDialog;