import {connectRouter, routerMiddleware} from 'connected-react-router';
import {reducer as toastrReducer} from 'react-redux-toastr';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import * as Locales from './Locales';
import * as PaymentMethod from './PaymentMethod';
import * as Terminal from './Terminal';
import * as Transaction from './Transaction';
import * as Configuration from './Configuration';
import * as Refund from './Refund';
import * as InviteToPay from './InviteToPay';
import * as Locations from './Locations';
import * as Square from './Square';

export function configureStore (history, initialState) {
  const reducers = {
    toastr: toastrReducer,
    locales: Locales.reducer,
    terminal: Terminal.reducer,
    paymentMethod: PaymentMethod.reducer,
    transaction: Transaction.reducer,
    configuration: Configuration.reducer,
    refund: Refund.reducer,
    inviteToPay: InviteToPay.reducer,
    locations: Locations.reducer,
    square: Square.reducer,
  };

  const middleware = [
    thunk,
    routerMiddleware(history)
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history)
  });

  return createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(...middleware), 
      ...enhancers
    )
  );
}
