import React from 'react';
import PropTypes from 'prop-types';
import { ToggleIconWrapper, ArrowWrapper, Arrow } from './styled';

const ToggleIcon = React.memo(({ active }) => (
  <ToggleIconWrapper>
    <ArrowWrapper>
      <Arrow active={active} />
    </ArrowWrapper>
  </ToggleIconWrapper>   
));

ToggleIcon.propTypes = {
  active: PropTypes.bool,
};

export default ToggleIcon;