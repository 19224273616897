import React, { useState, Fragment, useEffect, useMemo }  from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import { StyledDetailsRow } from './styled';
import RefundLineItem from '../RefundedLineItem';
import { StyledFormFeedback } from '../../../../shared/styled';
import { numberFormatOptions } from '../../../../shared/constants';
import { useInput, useDebounce, Row, Col, Checkbox, TextInput, InputGroup, InputGroupPrepend } from '@met/react-components';

const Tender = ({ tender, amount, setTendersToRefund, startChecked, currencyCode }) => {
  const [isChecked, setIsChecked] = useState(!!startChecked);
  const [isInvalidAmount, setIsInvalidAmount] = useState(false);
  const amountToRefund = useInput(amount / 100);
  const debouncedAmount = useDebounce(amountToRefund.value, 1000);

  const reason = useInput('');
  const debouncedReason = useDebounce(reason.value, 1250);
  
  const { refunds, tenderAmount } = tender;

  const decimalMaxRefund = useMemo(() => {
    const refundTotal = refunds.reduce((a, b) => a + (b['refundAmount'] || 0), 0);
    return (tenderAmount - refundTotal) / 100;
  }, [refunds, tenderAmount]);

  useEffect(() => {
    const isInvalid = amountToRefund ? (amountToRefund.value <= 0 || amountToRefund.value > decimalMaxRefund) : true;
    setIsInvalidAmount(isInvalid);
  }, [amountToRefund.value, reason, decimalMaxRefund]);

  useEffect(() => {
    const isInvalid = debouncedAmount ? (debouncedAmount <= 0 || debouncedAmount > decimalMaxRefund) : true;
    if (isChecked && !isInvalid) {
      setTendersToRefund({
        id: tender.id,
        amount: +debouncedAmount,
        reason: debouncedReason,
        type: tender.type
      }, !isChecked);
    } else if (isInvalid) {
      setTendersToRefund({
        id: tender.id,
        amount: +debouncedAmount,
        reason: debouncedReason,
        type: tender.type
      }, true);
    } else if (!isChecked) {
      setTendersToRefund({
        id: tender.id,
        amount: +debouncedAmount,
        reason: debouncedReason,
        type: tender.type
      }, !isChecked);
    }
  }, [isChecked, debouncedAmount, debouncedReason, decimalMaxRefund, setTendersToRefund, tender]);

  return (
    <Fragment>
      <Row>
        <Col xs={2} s={2} md={1} xl={1}>
          <Checkbox
            id={`radio-${tender.id}`}
            onCheck={data => setIsChecked(data.checked)}
            checked={isChecked}
          />
        </Col>
        <Col xs={4} s={4} md={3} xl={2}>
          {tender.customerCard ? tender.customerCard.cardholderName : tender.type === 'in-person' ? 'Swiped': 'unknown'}
        </Col>
        <Col xs={3} s={3}>
          {tender.customerCard ? tender.customerCard.cardLast4 : tender.cardLast4}
        </Col>
        <Col xs={3} s={3} md={2} xl={1}>
          <FormattedNumber
            {...numberFormatOptions}
            currency={currencyCode}
            value={tender.tenderAmount ? tender.tenderAmount / 100 : 0}
          />  
        </Col>
      </Row>
      {
        tender.refunds && tender.refunds.map(refund => { 
          return (
            <RefundLineItem 
              key={refund.id} 
              tenderRefunded={refund} 
              currencyCode={currencyCode}
            />
          )
        })
      }
      <StyledDetailsRow visible={isChecked}>
        <Col>
          <Row>
            <Col>
              Amount to Refund:
              <InputGroup sm>
                <InputGroupPrepend>$</InputGroupPrepend>
                <TextInput
                  id='refund'
                  placeholder={tender.tenderAmount ? tender.tenderAmount / 100 : 0}
                  invalid={isInvalidAmount} 
                  {...amountToRefund.bindToInput}
                />
                {isInvalidAmount &&
                <StyledFormFeedback>
                  The value is either less than 0 or greater than the amount available for the refund.
                </StyledFormFeedback>
                }
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              Reason:
              <InputGroup sm>
                <TextInput
                  id='reason'
                  placeholder="Reason"
                  {...reason.bindToInput}
                />
              </InputGroup>
            </Col>
          </Row>
        </Col>
      </StyledDetailsRow>
    </Fragment>
  );
};

Tender.propTypes = {
  tender: PropTypes.object,
  startChecked: PropTypes.bool,
  setTendersToRefund: PropTypes.func.isRequired
};

export default Tender;