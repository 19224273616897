import {GET_DEVICE_CODE, GET_DEVICE_STATUS} from "../Locations";
import * as types from './types';

const initialState = {
  terminals: [],
  selectedTerminal: null,
  getTerminals: {
    isLoading: false,
    isError: false,
  },
  sendToTerminal: {
    isLoading: false,
    isError: false,
  },
  checkoutDetails: {
    isLoading: false,
    isError: false,
    checkouts: []
  },
  additionalCheckout: {
    data: {},
    isLoading: false,
    isError: false
  },
  verifyCheckout: {
    data: {},
    isLoading: false,
    isError: false
  },
  simulateCheckout:{
    isLoading: false,
    isError: false    
  },
  pollCheckout:{
    isLoading: false,
    isError: false
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch(action.type) {
    case types.GET_TERMINALS_BY_LOCATION_ID:
      return {
        ...state,
        getTerminals: {
          isLoading: true,
          isError: false
        }
      };
    case types.GET_TERMINALS_BY_LOCATION_ID_SUCCESS:
      return {
        ...state,
        terminals: (action.payload.data ? action.payload.data.terminals : []) || [],
        getTerminals: {
          isLoading: false,
          isError: false
        }
      };
    case types.GET_TERMINALS_BY_LOCATION_ID_ERROR:
      return {
        ...state,
        getTerminals: {
          isLoading: false,
          isError: true
        }
      };
    case types.SEND_TO_TERMINAL:
      return {
        ...state,
        sendToTerminal: {
          isLoading: true,
          isError: false
        }
      };
    case types.SEND_TO_TERMINAL_SUCCESS:
      return {
        ...state,
        sendToTerminal: {
          isLoading: false,
          isError: false
        }
      };
    case types.SEND_TO_TERMINAL_ERROR:
      return {
        ...state,
        sendToTerminal: {
          isLoading: false,
          isError: true
        }
      };
    case types.SET_SELECTED_TERMINAL:
      return {
        ...state,
        selectedTerminal: action.selectedTerminal
      };
    case types.GET_CHECKOUT_DETAILS:
      return {
        ...state,
        checkoutDetails: {
          ...state.checkoutDetails,
          isLoading: true,
          isError: false          
        }
      };
    case types.GET_CHECKOUT_DETAILS_ERROR:
      return {
        ...state,
        checkoutDetails: {
          ...state.checkoutDetails,
          isLoading: false,
          isError: true
        }
      };
    case types.GET_CHECKOUT_DETAILS_SUCCESS:
      return {
        ...state,
        checkoutDetails: {
          isLoading: false,
          isError: false,
          checkouts: action.payload.data || [],
        }
      };

    case types.REQUEST_ADDITIONAL_CHECKOUT:
      return {
        ...state,
        additionalCheckout: {
          data: {},
          isLoading: true,
          isError: false
        }
      };
    case types.REQUEST_ADDITIONAL_CHECKOUT_ERROR:
      return {
        ...state,
        additionalCheckout: {
          ...state.additionalCheckout,
          isLoading: false,
          isError: true
        }
      };
    case types.REQUEST_ADDITIONAL_CHECKOUT_SUCCESS:
      return {
        ...state,
        additionalCheckout: {
          isLoading: false,
          isError: false,
          data: action.payload.data || {}
        }
      };

    case types.VERIFY_CHECKOUT:
      return {
        ...state,
        verifyCheckout: {
          ...state.verifyCheckout,
          isLoading: true,
          isError: false
        }
      };
    case types.VERIFY_CHECKOUT_ERROR:
      return {
        ...state,
        verifyCheckout: {
          ...state.verifyCheckout,
          isLoading: false,
          isError: true
        }
      };
    case types.VERIFY_CHECKOUT_SUCCESS:
      return {
        ...state,
        verifyCheckout: {
          isLoading: false,
          isError: false,
          data: action.payload.data || {}
        }
      };
    case types.SIMULATE_CHECKOUT:
      return {
        ...state,
        simulateCheckout: {
          isLoading: true,
          isError: false
        }
      };
    case types.SIMULATE_CHECKOUT_SUCCESS:
      return {
        ...state,
        simulateCheckout: {
          isLoading: false,
          isError: false
        }
      };
    case types.SIMULATE_CHECKOUT_ERROR:
      return {
        ...state,
        simulateCheckout: {
          isLoading: false,
          isError: true
        }
      };
    case `${GET_DEVICE_CODE}_SUCCESS`:
      if(state.selectedTerminal?.id === action.id){
        //update the available terminals and the selected terminal
        //with the latest info
        return {
          ...state,
          terminals: state.terminals ? state.terminals.map(i=>{
            if(i.id === action.id){
              return action.payload.data.terminal;
            }
            return i;
            }) : state.terminals,          
          selectedTerminal: action.payload.data.terminal
        }
      }else{
        //update the available terminals with the latest info
        return {
          ...state,
          terminals: state.terminals ? state.terminals.map(i=>{
            if(i.id === action.id){
              return action.payload.data.terminal;
            }
            return i;
          }) : state.terminals,
        };
      }
    case `${GET_DEVICE_STATUS}_SUCCESS`:
      if(state.selectedTerminal?.id === action.id){
        return {
          ...state,
          terminals: state.terminals ? state.terminals.map(i=>{
            if(i.id === action.id){
              return {
                ...i,
                deviceCodeId: action.payload.data.id,
                deviceId: action.payload.data.deviceId                
              };
            }
            return i;
          }) : state.terminals,
          selectedTerminal: {
            ...state.selectedTerminal,
            deviceCodeId: action.payload.data.id,
            deviceId: action.payload.data.deviceId
          }
        }
      }else{
        return {
          ...state,
          terminals: state.terminals ? state.terminals.map(i=>{
            if(i.id === action.id){
              return {
                ...i,
                deviceCodeId: action.payload.data.id,
                deviceId: action.payload.data.deviceId
              };
            }
            return i;
          }) : state.terminals,
        };
      }
    case types.POLL_CHECKOUT:
      return {
        ...state,
        pollCheckout: {
          isLoading: true,
          isError: false
        }
      };
    case types.POLL_CHECKOUT_SUCCESS:
      return {
        ...state,
        pollCheckout: {
          isLoading: false,
          isError: false
        }
      };
    case types.POLL_CHECKOUT_ERROR:
      return {
        ...state,
        pollCheckout: {
          isLoading: false,
          isError: true
        }
      };
    default:
        return state;
    }
};