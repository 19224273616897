import styled, { css } from 'styled-components';
import { Col, Grid } from '@met/react-components';
import { fadeIn, fadeInDown, fadeInLeft, fadeInRight } from '../../shared/styled';

const checkoutButtonColAnimation = ({ isMobileDevice }) => {
  return isMobileDevice 
    ? css`animation: ${fadeIn} ease-in-out .35s forwards;`
    : css`
        animation-name: ${fadeInDown};
        animation-duration: .8s;  
        animation-delay: .75s;
        animation-fill-mode: forwards;
      `
};

const leftPanelColAnimation = ({ isMobileDevice }) => {
  return isMobileDevice 
    ? css`animation: ${fadeIn} ease-in-out .35s;` 
    : css`animation: ${fadeInLeft} 1s;`
};

const rightPanelColAnimation = ({ isMobileDevice }) => {
  return isMobileDevice 
    ? css`animation: ${fadeIn} ease-in-out .35s;` 
    : css`animation: ${fadeInRight} 1s;`
};

const StyledLeftCol = styled(Col)`
  ${(props) => leftPanelColAnimation(props)}
`;

const StyledRightCol = styled(Col)`
  ${(props) => rightPanelColAnimation(props)}
`;

const StyledGrid = styled(Grid)`
  margin-bottom: 2rem;

  @media (max-width: 550px) {
    padding-right: .75rem;
    padding-left: .75rem;
  }

  & > div:first-of-type {
    margin: 1rem auto;
  }
`;

const StyledButtonCol = styled(Col)`
  opacity: 0;
  ${(props) => checkoutButtonColAnimation(props)}

  & > button {
    width: 100%;
    display: block;

    &:not(:first-of-type) {
      margin-top: 10px;
    }

    svg {
      font-size: 1.1rem;
      margin-right: .15rem;
      height: 1.1rem;
    }
  }
`;

export {
  StyledGrid,
  StyledLeftCol,
  StyledRightCol,
  StyledButtonCol
};