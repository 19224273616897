import { rebootCss } from '@met/react-components';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  ${rebootCss()}

  body {
    overflow-x: hidden; 
  }

  input::-ms-clear,
  input::-ms-reveal {  
    display: none; 
    width: 0; 
    height: 0; 
  }
`;

export default GlobalStyle;
