import { queryString, isObjectWithKeys } from '../helpers';
import { validCurrencyCodes } from '../shared/constants';
import { useEffect, useState } from 'react';
import { queryStringTranslation } from '../shared/constants';

export const useParsedQueryString = (translationObj = queryStringTranslation) => {
  const [state, setState] = useState({
    params: {},
    parsed: false,
    validCurrencyCode: true
  });

  useEffect(() => {
    const customParams = {};
    const parsedValues = queryString.parse();
    let validCurrencyCode = true;

    if (!isObjectWithKeys(translationObj)) {
      setState({
        params: parsedValues,
        parsed: true,
        validCurrencyCode: validCurrencyCode
      });
      return;    
    }

    Object.keys(translationObj).forEach((key) => {
      const parsedValuesKey = translationObj[key];
      if (parsedValuesKey) {
        const getMappedValue = parsedValues[parsedValuesKey];
        customParams[key] = getMappedValue || '';
        if (key === 'currencyCode' && getMappedValue && !validCurrencyCodes.find(code => code === getMappedValue)) {
          validCurrencyCode = false;
        }
      }
    });
      
    setState({
      params: customParams,
      parsed: true,
      validCurrencyCode
    });    
  }, [translationObj]);



  return {
    queryParams: state.params, 
    isParsed: state.parsed, 
    validCurrencyCode: state.validCurrencyCode
  };
};