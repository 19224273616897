import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import OrderSummary from '../../../../components/OrderSummary';
import { numberFormatOptions, defaultCurrencyCode } from '../../../../shared/constants';

const TranDetails = React.memo(({ amountReceived, amountRemaining, transaction }) => (
  <Fragment>
    <OrderSummary 
      separator
      label='Requested' 
      value={(
        <FormattedNumber
          {...numberFormatOptions}
          currency={transaction.currencyCode || defaultCurrencyCode}
          value={transaction.total ? transaction.total / 100 : 0}
        />
      )} 
    />
    <OrderSummary 
      separator
      label='Received' 
      value={(
        <FormattedNumber
          {...numberFormatOptions}
          currency={transaction.currencyCode || defaultCurrencyCode}
          value={amountReceived ? amountReceived / 100 : 0}
        />
      )} 
    />
    <OrderSummary
      label='Remaining' 
      value={(
        <FormattedNumber
          {...numberFormatOptions}
          currency={transaction.currencyCode || defaultCurrencyCode}
          value={amountRemaining ? amountRemaining / 100 : 0}
        />
      )} 
    />
  </Fragment>
));

TranDetails.propTypes = {
  amountReceived: PropTypes.number,
  amountRemaining: PropTypes.number,
  transaction: PropTypes.object,
};

export default TranDetails;