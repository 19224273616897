import React, { Fragment, useEffect } from 'react';
import GlobalStyle from '../GlobalStyle';
import { StyledMainGrid } from './styled';
import Navbar from '../../components/Navbar';
import AccessDenied from '../../components/AccessDenied';
import InvalidCurrency from '../../components/InvalidCurrency';
import { useJwtAuthentication, useParsedQueryString, useEntraAuthentication } from '../../hooks';
import { Modal, ModalBody, ModalContent, ModalHeader, PageLoader, useToggle } from '@met/react-components';
import { isUsingEntraAuth } from '../../msal';

const Layout = (props) => {
  const [isOpen, toggleModal] = useToggle(false);

  // Doing that as we can't call hooks conditionally
  const jwtAuth = useJwtAuthentication();
  const entraAuth = useEntraAuthentication();
  const auth = !isUsingEntraAuth ? jwtAuth : entraAuth;
  const [authenticated, error, errorText, isLoading] = auth;

  const { isParsed, validCurrencyCode } = useParsedQueryString();

  useEffect(() => {
    let handleKeyboardEvent = (e) => {
      if (e.altKey && e.key === 'd') {
        toggleModal();
      }
    }
    document.addEventListener('keydown', handleKeyboardEvent, false);
    return () => {
      document.removeEventListener('keydown', handleKeyboardEvent, false);
    };
  });

  let chillins = '';

  if (isLoading) {
    chillins = <PageLoader />;
  } else if (error && errorText) {
    chillins = <AccessDenied overrideText={errorText} />;
  } else if (error) {
    chillins = <AccessDenied overrideText={'Unknown Error logging in'} />;
  }
  else if (!validCurrencyCode) {
    chillins = <InvalidCurrency />;
  }
  else if (authenticated && !isParsed) {
    chillins = <AccessDenied overrideText={'Unknown Error parsing query string: ' + window.location.href} />;
  }
  else if (authenticated && (isParsed && validCurrencyCode)) {
    chillins = props.children;
  }

  return (
    <Fragment>
      <Navbar />
      <StyledMainGrid>
        {chillins}
      </StyledMainGrid>
      <Modal hidden={!isOpen} toggle={toggleModal} centered={true} size={'md'}>
        <ModalContent>
          <ModalHeader toggle={toggleModal}>
            Current Location
          </ModalHeader>
          <ModalBody>
            <span>{window.location.href}</span>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default Layout;