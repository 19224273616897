export const queryString = {
  parse: function parse() {
    let queryString = window.location.search;    
    let query = {};
    let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
      let pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  },
  update: (key, value, url, defaultValue) => {
    let stringValue;
    if (value !== undefined && value !== null)
      stringValue = value.toString();
    let stringDefaultValue;
    if (defaultValue !== undefined && defaultValue !== null)
      stringDefaultValue = defaultValue.toString();
    
    if (!url) url = window.location.pathname + window.location.search;
    // remove the hash part before operating on the uri
    let i = url.indexOf('#');
    let hash = i === -1 ? ''  : url.substr(i);
    url = i === -1 ? url : url.substr(0, i);

    let re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    let separator = url.indexOf('?') !== -1 ? "&" : "?";

    if (!stringValue || stringValue === '' || stringValue === stringDefaultValue) {
      // remove key-value pair if value is empty
      url = url.replace(new RegExp("([?&])" + key + "=[^&]*", "i"), '');
      if (url.slice(-1) === '?') {
        url = url.slice(0, -1);
      }
      // replace first occurrence of & by ? if no ? is present
      if (url.indexOf('?') === -1) url = url.replace(/&/, '?');
    } else if (url.match(re)) {
      url = url.replace(re, '$1' + key + "=" + stringValue + '$2');
    } else {
      url = url + separator + key + "=" + stringValue;
    }
    return url + hash;
  },
  objectToQuery: (queryObject) => {
    return Object
      .keys(queryObject)
      .filter(Boolean)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(queryObject[k])}`)
      .join('&');
  }
};