import * as types from './types';
import createFetchAction from '../createFetchAction';

export const actionCreators ={
  getTransaction: (transactionId) => {
    return createFetchAction({
      method: 'GET',
      url: `api/v1/transactions/${transactionId}`,
      startAction: types.GET_TRANSACTION_DETAILS
    });
  },  
  getTransactionsForExternal: (externalType, externalId, onlyCompletedPayments = false) => {
      return createFetchAction({
          method: 'GET',
          url: `/api/v1/transactions/${externalType}/${externalId}?onlyCompletedPayments=${onlyCompletedPayments}`,
          startAction: types.GET_TRANSACTIONS_FOR_EXTERNAL
      });
  }
};