import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import OrderSummary from '../../../../components/OrderSummary';
import { Card, CardBody, CardFooter, CardHeader } from '@met/react-components';
import { numberFormatOptions, defaultCurrencyCode } from '../../../../shared/constants';

const TranDetails = React.memo(({ queryParams }) => (
  <Card>
    <CardHeader>Payment Summary</CardHeader>
    <CardBody>
      <OrderSummary 
        label='Sales ID' 
        value={queryParams.salesId} 
        separator 
      />
      <OrderSummary 
        label='PO #' 
        value={queryParams.purchaseOrderNumber} 
        separator 
      />
      <OrderSummary 
        label='Journal #' 
        value={queryParams.journalId} 
        separator 
      />
      <OrderSummary 
        label='Service Order' 
        value={queryParams.serviceOrderId} 
        separator 
      />
      <OrderSummary 
        label='Account #' 
        value={queryParams.accountNumber} 
      />
    </CardBody>
    <CardFooter>
      <OrderSummary 
        label='Total' 
        value={(
          <FormattedNumber
            {...numberFormatOptions}
            value={queryParams.amount || 0}
            currency={queryParams.currencyCode || defaultCurrencyCode}
          />
        )} 
      />
    </CardFooter>
  </Card>
));

TranDetails.propTypes = {
  queryParams: PropTypes.shape({
    currencyCode: PropTypes.string, 
    accountNumber: PropTypes.string,
    purchaseOrderNumber: PropTypes.string,
    salesId: PropTypes.oneOfType([
      PropTypes.string, 
      PropTypes.number
    ]),
    journalId: PropTypes.oneOfType([
      PropTypes.string, 
      PropTypes.number
    ]),
    serviceOrderId: PropTypes.oneOfType([
      PropTypes.string, 
      PropTypes.number
    ]),
    amount: PropTypes.oneOfType([
      PropTypes.string, 
      PropTypes.number
    ]),
    transactionId: PropTypes.oneOfType([
      PropTypes.string, 
      PropTypes.number
    ]),
    terminalId: PropTypes.oneOfType([
      PropTypes.string, 
      PropTypes.number
    ]),
  })
};
  

export default TranDetails;