import styled from 'styled-components';
import { Grid } from '@met/react-components';

const StyledMainGrid = styled(Grid)`
  margin-top: 84px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export {
  StyledMainGrid
};