/**
 * Translation definitions for window.location.search query - values are the query string keys and keys are our new properties
 * Example: http://localhost:5020/collect?&t=salesId&s=23212&a=100001091&c=USD&m=100&l=3041
 * Schemas are defined in the Met.Trap.Web project
 */
export const queryStringTranslation = {
  type: 't',
  amount: 'm',
  salesId: 's',
  invoiceId: 'i',
  journalId: 'j',
  locationId: 'l',
  currencyCode: 'c',
  accountNumber: 'a',
  serviceOrderId: 'so',
  purchaseOrderNumber: 'po',
  transactionId: 'tr',
  terminalId: 'd',
  refundKey: 'k',
  externalSystem: 'e'
};

export const transactionTypeEnum = {
  SALES_ID: 'SALESID',
  JOURNAL_ID: 'JOURNALID',
  SERVICE_ORDER_ID: 'SERVICEORDERID',
  SERVICE_PREAUTH: 'SERVICEPREAUTH'
};