import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {ApplicationInsights} from "@microsoft/applicationinsights-web";
import history from './history';

let instrumentationKey = 'cfbced87-fb3e-458f-83b9-4211ad8c5412';
let host = window.location.hostname;

if (host.indexOf('stage') >= 0)
    instrumentationKey = '2c27f11c-d8dc-4527-97a8-104e6486dc95';
else if (host.indexOf('trap.milwaukeetool.com') >= 0)
    instrumentationKey = '3b37d9ce-80dd-44b8-9771-462175b71d6b';

let reactPlugin = new ReactPlugin();
let appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: instrumentationKey,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            debug: false,
            history: history
          }
        }
    }
});

appInsights = appInsights.loadAppInsights();

export default {
  trackPageView:(pv)=>{
    if(appInsights) {
      appInsights.trackPageView(pv);
    }
  },
  trackTrace: (tr)=>{
    if(appInsights) {
      appInsights.trackTrace(tr);
    }
  }
};