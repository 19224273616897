import {Button, ButtonGroup, Card, CardHeader, Col, Grid, Icon, PageLoader, Row, Table,} from '@met/react-components';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import {useHistory} from "react-router";
import AccessCodeModal from "../../components/AccessCodeModal";
import {locationActions} from '../../store';

const Locations = ()=>{
    const dispatch = useDispatch();
    const history = useHistory();
    
    const locations = useSelector(state => state.locations.locations);
    const devices = useSelector(state => state.locations.devices);
    const isGetCodeLoading = useSelector(state=>state.locations.getDeviceCode.isLoading);
    const isGetCodeError = useSelector(state=>state.locations.getDeviceCode.isError);
    const isRefreshLoading = useSelector(state=>state.locations.getDeviceStatus.isLoading);
    const deviceCode = useSelector(state=>state.locations.deviceCode);
    
    const [flattenedLocations, setFlattenedLocations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isCodeDialogOpen, setIsCodeDialogOpen] = useState(false);
    
    useEffect(()=>{
      dispatch(locationActions.listLocations()).catch(()=>{
        toastr.error('Error: Locations', `Could not get locations.`);
      }).finally(()=>{
        setIsLoading(false);
      });
    }, []);
    
    useEffect(()=>{
      if(locations){
       let items = [];
       
       let deviceMap = {};
       
       if(devices) {
        devices.map((item) => {
          deviceMap[item.id] = item;
         });
       }
       

        for (let locIndex = 0; locIndex < locations.length; locIndex++){
          let loc = locations[locIndex];
          
          if(loc.terminals?.length) {
            for (let termIndex = 0; termIndex < loc.terminals.length; termIndex++) {
              let terminal = loc.terminals[termIndex];
              
              let deviceInfo = terminal.deviceCodeId ?  deviceMap[terminal.deviceCodeId] || { status: 'Not Found' } : { };
              
              items.push({
                locationId: loc.locationId,
                squareLocationId: loc.squareLocationId,
                allowTestCards: loc.allowTestCards,
                
                id: terminal.id,
                terminalId: terminal.terminalId,
                terminalName: terminal.terminalName,
                deviceCodeId: terminal.deviceCodeId,
                deviceId: terminal.deviceId,
                status: deviceInfo.status
              });
            }
          } else {
            items.push({
              locationId: loc.locationId,
              squareLocationId: loc.squareLocationId,
              id: loc.locationId,
              allowTestCards: loc.allowTestCards
            });
          }          
        }
        
        setFlattenedLocations(items);        
      }else{
        setFlattenedLocations([]);
      }
    }, [locations, devices]);
    
    const getCode = (id) => {
      setIsCodeDialogOpen(true);
      dispatch(locationActions.getDeviceCode(id)).catch(()=>{
        toastr.error('Error: Getting Device Code', `Could not get device code.`);
      });
    };
    
    const refreshStatus = (id) => {
      dispatch(locationActions.getDeviceStatus(id))
        .then(()=>{
          toastr.info("Refreshed", "Status updated");
        })
        .catch(()=>{
          toastr.error('Error: Refreshing Device Status', `Could not refresh status.`);
        });
    }
    
    const newTerminal = (locationId) =>{
      if(locationId)
        history.push(`/admin/locations/newTerminal?locationId=${locationId}`);
      else
        history.push(`/admin/locations/newTerminal`);        
    }
    
    const columns = {
      locationId:{
        label: 'Location'        
      },
      allowTestCards:{
        label: 'Test Cards',
        content: (item) => (item.allowTestCards ? 'Yes' : 'No')
      },
      squareLocationId: {
        label: 'Sq Loc Id'
      },
      terminalName: {
        label: 'Terminal'
      },
      terminalId: {
        label: 'Serial #'
      },
      deviceCodeId: {
        label: 'Square Id'
      },
      deviceId:{
        label: 'Square Device Id'
      },
      status: {
        label: 'Status'
      },
      actions:{
        label: '',
        content: (item)=> {
          if (!item.allowTestCards) {
            if (item.deviceCodeId) {
              return (
                <ButtonGroup>
                  <Button loading={isRefreshLoading} onClick={() => refreshStatus(item.id)} icon='recycle' title='Refresh Status' success  />
                  <Button loading={isGetCodeLoading} onClick={() => getCode(item.id)} icon={'mobile-alt'} title='Get New Code' />
                </ButtonGroup>
              );
            } else if (item.terminalId) {
              return (
                <ButtonGroup>
                  <Button loading={isGetCodeLoading} onClick={() => getCode(item.id)} icon={'mobile-alt'} title='Get New Code' />
                </ButtonGroup>
              );
            }
            return "";
          }
          return "Not allowed in test mode";
        }        
      }
    }
  
    if(isLoading){
      return (<Grid>
        <Row>
          <Col>
            <PageLoader />
          </Col>
        </Row>
      </Grid>)
    }

  return (<Grid>
    <Row>
      <Col>
        <Card>
          <CardHeader>
            Locations/Terminals            

            <div style={{marginLeft: 'auto'}} id='table-actions' />
          </CardHeader>
          <Table
            columns={columns}
            data={flattenedLocations}
            actionsToolbar={{
              targetElementId: 'table-actions',
              predefinedActions: {
                columns: {
                  enabled: false
                }
              },
              customActions: [
                {
                  label: 'Add Terminal',
                  icon: <Icon type='plus-circle' />,
                  onClick: () => newTerminal(),
                  disabled: false
                }
              ]
            }}
          />
        </Card>       
      </Col>
    </Row>
    <AccessCodeModal
      isLoading={isGetCodeLoading}
      isError={isGetCodeError}
      isOpen={isCodeDialogOpen}
      accessCode={deviceCode.code}
      handleOk={()=>setIsCodeDialogOpen(false)}
    />
  </Grid>
  );
}

export default Locations;