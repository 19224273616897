import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  InputGroup,
  InputGroupPrepend,
  Dropdown,
  InputGroupText,
  TextInput,
  PageLoader,
  ModalContent,
  ModalHeader,
  ModalBody,
  LoadingDots,
  Text, ModalFooter, Modal
} from "@met/react-components";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toastr} from "react-redux-toastr";
import {useHistory} from "react-router";
import AccessCodeModal from "../../components/AccessCodeModal";
import {queryString} from "../../helpers";
import {StyledGrid, StyledRow} from "../../shared/styled";
import {locationActions} from "../../store";


const newDeviceId = 'NewDevice';

const NewTerminal = () =>{
  const dispatch = useDispatch();
  const history = useHistory();
  
  const devices = useSelector(state => state.locations.devices);
  const locations = useSelector(state => state.locations.locations);
  const isSaving = useSelector(state=>state.locations.createDevice.isLoading);
  const isSavingError = useSelector(state=>state.locations.createDevice.isError);
  const createdDevice = useSelector(state=>state.locations.createdDevice);
  
  const [isCodeDialogOpen, setIsCodeDialogOpen] = useState(false);
  const [availableLocations, setAvailableLocations] = useState([]);
  const [availableDevices, setAvailableDevices] = useState([]);  
  const [isLoading, setIsLoading] = useState(true);
  const [locationId, setLocationId] = useState(null);
  const [terminalId, setTerminalId] = useState('');
  const [terminalName, setTerminalName] = useState('');
  const [deviceCodeId, setDeviceCodeId] = useState(null);
  const [deviceId, setDeviceId] = useState(null);

  useEffect(()=>{
    dispatch(locationActions.listLocations()).catch(()=>{
      toastr.error('Error: Locations', `Could not get locations.`);
    }).finally(()=>{
      setIsLoading(false);
    });
  }, []);
  
  useEffect(()=>{    
    if(devices){
     let dev = devices.map((item)=>{
       return {
         label: `${item.name} (${item.status})`,
         value: item.id,
         deviceId: item.deviceId
       }
     }); 
     let newDev = {
       label: 'New Device',
       value: newDeviceId
     };
     dev.unshift(newDev);
     setAvailableDevices(dev);
     if(!deviceCodeId){
       setDeviceCodeId(newDeviceId);
     }
    }    
  }, [devices]);
  
  useEffect(()=>{
    if(locations){      
      let locs = locations.filter(f=>!f.allowTestCards).map(i=>{
        return {
          label: i.locationId,
          value: i.locationId
        }
      });
      
      setAvailableLocations(locs);
      
      if(!locationId){
        let query = queryString.parse();
        let locId = query['locationId'];        
        if(locId) {
          setLocationId(locId);
        }
      }
    }
  }, [locations]);
  
  const saveTerminal = ()=>{
    let data = {
      terminalId,
      locationId: locationId,
      terminalName,
      deviceCodeId: deviceCodeId,
      deviceId: deviceId
    };
    
    if(!data.terminalName || !data.terminalId || !data.locationId || !data.deviceCodeId){
      toastr.error('Validation Error', 'Please fill in all fields');
      return;
    }
    
    if(data.deviceCodeId === newDeviceId){
      delete data.deviceCodeId;
      delete data.deviceId;
    }
    
    if(!data.deviceCodeId) {
      setIsCodeDialogOpen(true);
    }
    dispatch(locationActions.createDevice(data))
      .finally(()=>{
        if(data.deviceCodeId) {
          history.push('/admin/locations');
        }
      })
      .catch(()=>{
        toastr.error('Error', 'Error creating new device');
      });
  };
  
  const handleLocationChanged = (val) =>{
    setLocationId(val?.value);
  }
  
  const handleDeviceChanged = (val) =>{
    setDeviceCodeId(val?.value)
    setDeviceId(val?.deviceId);
  }
  
  const handleCodeDialogClose = ()=>{
    if(!isSavingError) {
      history.push('/admin/locations');
    }
  }
  
  return (
    <Card>      
      <CardHeader>New Terminal</CardHeader>
      {isLoading && <PageLoader />}
      {!isLoading && (
        <React.Fragment>
          <CardBody>
            <StyledGrid fluid>
              <StyledRow>
                <InputGroup>
                  <InputGroupPrepend><InputGroupText style={{minWidth: '70px'}}>Location</InputGroupText></InputGroupPrepend>
                  <Dropdown 
                    options={availableLocations}
                    getOptionValue={(option) => (option.value)}
                    getOptionLabel={(option) => (option.label)}
                    value={locationId}
                    onChange={handleLocationChanged}
                  />
                </InputGroup>
              </StyledRow>
              <StyledRow>
                <InputGroup>
                  <InputGroupPrepend><InputGroupText style={{minWidth: '70px'}}>Device</InputGroupText></InputGroupPrepend>
                  <Dropdown
                    options={availableDevices}
                    getOptionValue={(option) => (option.value)}
                    getOptionLabel={(option) => (option.label)}
                    value={deviceCodeId}
                    onChange={handleDeviceChanged}
                  />
                </InputGroup>
              </StyledRow>
              <StyledRow>
                <InputGroup>
                  <InputGroupPrepend><InputGroupText style={{minWidth: '70px'}}>Name</InputGroupText></InputGroupPrepend>
                  <TextInput value={terminalName} onChange={(e)=>setTerminalName(e.target.value)} valid={terminalName?.length > 0} />
                </InputGroup>
              </StyledRow>
              <StyledRow>
                <InputGroup>
                  <InputGroupPrepend><InputGroupText style={{minWidth: '70px'}}>Serial #</InputGroupText></InputGroupPrepend>
                  <TextInput value={terminalId} onChange={(e)=>setTerminalId(e.target.value)} valid={terminalId?.length > 0} />
                </InputGroup>
              </StyledRow>
            </StyledGrid>
          </CardBody>
          <CardFooter>
            <Button onClick={saveTerminal} loading={isSaving} primary >Save</Button>
            <Button onClick={() => history.push('/admin/locations')} secondary style={{marginLeft: '5px'}}>Cancel</Button>
          </CardFooter>
        </React.Fragment>
      )}
      <AccessCodeModal 
        isLoading={isSaving} 
        isError={isSavingError} 
        isOpen={isCodeDialogOpen} 
        accessCode={createdDevice.accessCode} 
        handleOk={handleCodeDialogClose} 
      />     
    </Card>
    
  );
};

export default NewTerminal;