import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalContent } from '@met/react-components';
import { StyledInfoIconContainer, StyledMessage, StyledMessageHeader, StyledModalFooter } from './styled';

const RemainingBalanceModal = React.memo(({ isHidden, isLoading, toggle, onRequestCheckout }) => {
  const handleOnRequestCheckout = () => {
    onRequestCheckout().then(() => toggle(false));
  };

  return (
    <Modal hidden={isHidden} centered={true} size={'md'}>
      <ModalContent>
        <ModalBody>
          <StyledInfoIconContainer />
          <StyledMessageHeader>Remaining Balance Found!</StyledMessageHeader>
          <StyledMessage>Click OK to send funds to the terminal</StyledMessage>
        </ModalBody>
        <StyledModalFooter>
          <Button loading={isLoading} onClick={handleOnRequestCheckout}>OK</Button>
        </StyledModalFooter>
      </ModalContent>
    </Modal>
  );
});

RemainingBalanceModal.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onRequestCheckout: PropTypes.func.isRequired,
};

export default RemainingBalanceModal;