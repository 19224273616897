import React from 'react';
import logo from './MT_logo.svg';
import { StyledLogo, StyledNavbar } from './styled';

const Navbar = () => (
  <StyledNavbar>
    <StyledLogo>
      <img src={logo} width='124' height='67' alt='logo' />
    </StyledLogo>
  </StyledNavbar>
);

export default Navbar;