import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalContent } from '@met/react-components';
import { StyledMessage, StyledMessageHeader, StyledMessageContainer, StyledModalFooter, AnimatedSuccessIcon } from './styled';

const TranCompleteModal = ({ onModalClose }) => {
  const [animationClass, setAnimationClass] = useState(null);

  useEffect(() => {
    if (animationClass) {
      setTimeout(() => setAnimationClass(null), 1300);
    }
  }, [animationClass]);

  const closeWindow = () => {
    if (window.mkeChromeBrowser) {
      window.mkeChromeBrowser.closeDialog();
    } else {
      setAnimationClass('heartBeat');

      if (onModalClose) {
        onModalClose();
      }
    }
  };

  return (
    <Modal hidden={false} centered={true} size={'md'}>
      <ModalContent>
        <ModalBody>
          <AnimatedSuccessIcon />
          <StyledMessageHeader>Transaction Complete!</StyledMessageHeader>
          <StyledMessageContainer>
            <StyledMessage className={animationClass}>Please close the window</StyledMessage>
          </StyledMessageContainer>
        </ModalBody>
        <StyledModalFooter>
          <Button onClick={closeWindow}>Close</Button>
        </StyledModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TranCompleteModal;