import React from 'react';
import { StyledAlert } from '../../shared/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InvalidCurrency = () => (
  <StyledAlert danger>
    <FontAwesomeIcon icon='exclamation-circle' /> Currency code is not supported
  </StyledAlert>
);

export default InvalidCurrency;