import React from 'react';
import PropTypes from 'prop-types';
import { StyledTableWrapper } from './styled';
import { Table } from '@met/react-components';
import { amountsTableColumns } from './constants';

const AmountsTable = ({ checkouts }) => (
  <StyledTableWrapper>
    <Table
      striped
      borderless
      data={checkouts}
      columns={amountsTableColumns}
    /> 
  </StyledTableWrapper>
);

AmountsTable.propTypes = {
  checkouts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      cancelReason: PropTypes.string,
      currencyCode: PropTypes.string,
      transactionId: PropTypes.oneOfType([
        PropTypes.string, 
        PropTypes.number
      ]),
      terminalId: PropTypes.oneOfType([
        PropTypes.string, 
        PropTypes.number
      ]),
      checkoutAmount: PropTypes.oneOfType([
        PropTypes.string, 
        PropTypes.number
      ]),
      receivedAmount: PropTypes.oneOfType([
        PropTypes.string, 
        PropTypes.number
      ]),
      checkoutStatus: PropTypes.oneOfType([
        PropTypes.string, 
        PropTypes.number
      ]),
  }))
};

export default AmountsTable;