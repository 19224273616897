import * as types from './types';

const initialState = {
  previousInvites: {
    data: [],
    isLoading: false,
    isError: false
  },
  deleteInvite: {
    isLoading: false,
    isError: false
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch(action.type) {
    case types.GET_PREVIOUS_INVITES:
      return {
        ...state,
        previousInvites: {
          ...state.previousInvites,
          isLoading: true,
          isError: false
        }
      }
    case types.GET_PREVIOUS_INVITES_ERROR:
      return {
        ...state,
        previousInvites: {
          ...state.previousInvites,
          isLoading: false,
          isError: true
        }
      }
    case types.GET_PREVIOUS_INVITES_SUCCESS:
      return {
        ...state,
        previousInvites: {
          data: action.payload ? action.payload.data : [],
          isLoading: false,
          isError: false
        }
      }
    case types.DELETE_INVITE:
      return {
        ...state,
        deleteInvite: {
          isLoading: true,
          isError: false
        }
      }
    case types.DELETE_INVITE_ERROR:
      return {
        ...state,
        deleteInvite: {
          isLoading: false,
          isError: true
        }
      }
    case types.DELETE_INVITE_SUCCESS:
        const prevInviteDataMinusDeleted = state.previousInvites.data.filter((inviteItem) => inviteItem.inviteToPayId !== action.inviteToPayId);
        return {
          ...state,
          previousInvites: {
            ...state.previousInvites,
            data: prevInviteDataMinusDeleted
          },
          deleteInvite: {
            isLoading: false,
            isError: false
          }
        }
    default:
      return state;
  }
};

