import styled from 'styled-components';
import { lightSpeedOut, fadeIn } from '../../../../shared/styled'
import { Row } from '@met/react-components';

const PreviousInvitesWrapper = styled.div`
  overflow-x: hidden; 
`;

const PreviousInviteRow = styled(Row)`
  padding-top: .25rem;
  padding-bottom: .25rem;

  & + div {
    margin-top: 0rem;
    padding-top: .35rem;
  }

  button {
    vertical-align: top;
    padding: 0.03rem 0.5rem;
  }

  &.previousInvite-enter-active {
    animation-name: ${fadeIn};
    animation-timing-function: ease-in-out;
    animation-duration: .35s;
    animation-fill-mode: both;
  }

  &.previousInvite-exit-active {
    animation-name: ${lightSpeedOut};
    animation-timing-function: ease-in;
    animation-duration: .5s;
    animation-fill-mode: forwards;
  }
`;

export {
  PreviousInviteRow,
  PreviousInvitesWrapper,
};