import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledInfoIcon = styled(FontAwesomeIcon)`
  font-size: 1.25em;
  margin-right: .5rem;
  width: 1.05em;
  height: 1.05em;
  vertical-align: -0.2em;
  color: #6c757d;
`;

const StyledList = styled.ul`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  
  & > li {
    padding-bottom: .5rem;
  }
`;

export {
  StyledList,
  StyledInfoIcon
};