import styled, { css } from 'styled-components';
import { StyledRow } from '../../shared/styled';

const hidePreviousInvites = (props) => (
  props.hide && css`
    margin-bottom: 0;
  `
);

const StyledPreviousInviteWrapper = styled(StyledRow)`
  ${props => hidePreviousInvites(props)}
`;

const StyledAppendIconContainer = styled.div`
  width: auto;
  padding: 0 .75rem;
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translate(-100%, -50%);
  display: flex;
`;

const StyledHelpIcon = styled.div`
  margin-left: .75rem;            
  background: transparent;

  & svg[data-icon='times'] {
    color: #db011c;  
    cursor: default;
  }

  & svg[data-icon='question-circle'] {
    color: #6c757d;  
    cursor: pointer;

    :hover {
      opacity: .85;
    }
  }
`;

export {
  StyledHelpIcon,
  StyledAppendIconContainer,
  StyledPreviousInviteWrapper,
};