import * as types from './types';

const initialState = {  
  getTransaction: {
    isLoading: false,
    isError: false,
    data: {}
  },
  getTransactionsForExternal: {
    isLoading: false,
    isError: false,
    data: []
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch(action.type) {
    case types.GET_TRANSACTION_DETAILS:
      return {
        ...state,
        getTransaction:{
          ...state.getTransaction,
          isLoading: true,
          isError: false,
        }
      };
    case types.GET_TRANSACTION_DETAILS_ERROR:
      return {
        ...state,
        getTransaction:{
          ...state.getTransaction,
          isLoading: false,
          isError: true,       
        }
      };
    case types.GET_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        getTransaction:{
          isLoading: false,
          isError: false,
          data: action.payload.data || {}
        }
      };

    case types.GET_TRANSACTIONS_FOR_EXTERNAL:
      return {
        ...state,
        getTransactionsForExternal: {
          ...state.getTransactionsForExternal,
          isLoading: true,
          isError: false
        }
      };
    case types.GET_TRANSACTIONS_FOR_EXTERNAL_SUCCESS:
      return {
        ...state,
        getTransactionsForExternal: {
          isLoading: false,
          isError: false,
          data: action.payload || []
        }
      };
    case types.GET_TRANSACTIONS_FOR_EXTERNAL_ERROR:
      return {
        ...state,
        getTransactionsForExternal: {
          ...state.getTransactionsForExternal,
          isLoading: false,
          isError: true
        }
      }

    default:
      return state;
  }
};