import React, { useEffect, useCallback, useState, useReducer } from 'react';
import { connect } from 'react-redux';
import { Tender } from './components';
import {FormattedNumber} from 'react-intl';
import { toastr } from 'react-redux-toastr';
import { useParsedQueryString } from '../../hooks';
import OrderSummary from '../../components/OrderSummary';
import TranCompleteModal from '../../components/TranComplete';
import { numberFormatOptions } from '../../shared/constants';
import { transactionActions, refundActions } from '../../store';
import { StyledContentWrapper, StyledHeaderH2 } from '../../shared/styled';
import { Button, Card, CardHeader, CardBody, PageLoader, Row, Col } from '@met/react-components';
import { getTransactionsForExternalKVP, getExternalIdTypeName, isArrayWithLength } from '../../helpers';

const Refund = ({ transaction, getTransactions, requestRefund }) => {
  const preTenders = !transaction.getTransactionsForExternal.isLoading 
    && isArrayWithLength(transaction.getTransactionsForExternal.data) 
    && transaction.getTransactionsForExternal.data.map(transaction => transaction.tenders || []).reduce((a,b) => a.concat(b)).filter(t => t.status === 3 || t.status === 7)
      .map(tender => {
        tender.type = 'web';
        return tender;
      });  
  
  let checkoutPayments = false;
  
  if(!transaction.getTransactionsForExternal.isLoading
    && isArrayWithLength(transaction.getTransactionsForExternal.data)){
    checkoutPayments = transaction.getTransactionsForExternal.data.map(transaction => transaction.checkouts || [])
      .reduce((a,b) => a.concat(b))
      .filter(t => isArrayWithLength(t.payments));
    
    if(isArrayWithLength(checkoutPayments)){
      checkoutPayments = checkoutPayments.map(checkout => checkout.payments)
        .reduce((a,b) => a.concat(b))
        .filter(t => t.cardStatus === 1)
        .map(payment =>{
          payment.type = 'in-person';
          payment.tenderAmount = payment.paymentAmount; //hack
          return payment;
        });
    }
  }
  
  
  const tenders = preTenders && checkoutPayments ?  preTenders.concat(checkoutPayments) :
            preTenders ? preTenders : checkoutPayments ? checkoutPayments : [];  
  
  const refunds = isArrayWithLength(tenders) ? tenders.map(tender => tender.refunds || []) : false;
  const tenderTotal = isArrayWithLength(tenders) ? tenders.reduce((a, b) => a + (b['tenderAmount'] || b['paymentAmount'] || 0), 0) : 0;
  const refundedAmount = isArrayWithLength(refunds) ? refunds.reduce((a,b) => a.concat(b)).reduce((a,b) => a + (b['refundAmount'] || b['paymentAmount']  || 0), 0) : 0;

  const { queryParams, isParsed } = useParsedQueryString();
  const { type, amount, accountNumber } = queryParams;

  const [success, setSuccess] = useState(false);
  const [refetchTransactions, setRefetchTransactions] = useState(false);
  const [currencyCode, setCurrencyCode] = useState('USD');
  // Because of some miscommunitcation, AX will be sending the amount in the accountNumber parameter
  // Trying to make this default to the amount number, but check accountNumber if amount isn't set
  let remainingToRefund = 0;

  if (!isNaN(parseInt(amount))) {
    remainingToRefund = +amount * 100;
  } else if (!isNaN(parseInt(accountNumber))) {
    remainingToRefund = +accountNumber * 100;
  }

  useEffect(() => {
    if (isParsed) {
      const { externalType, externalId } = getTransactionsForExternalKVP(queryParams);
      getTransactions(externalType, externalId).catch((e) => {
        toastr.error('Error: Get Transactions', 'Could not retrieve transactions');
      });
    }
  }, [isParsed, queryParams]);

  useEffect(() => {
    if (refetchTransactions) {
      const { externalType, externalId } = getTransactionsForExternalKVP(queryParams);
      getTransactions(externalType, externalId)
        .catch((e) => toastr.error('Error: Get Transactions', 'Could not retrieve transactions'))
        .then(() => setRefetchTransactions(false));
    }    
  }, [refetchTransactions, queryParams]);

  useEffect(() => {
      if(transaction.getTransactionsForExternal 
        && transaction.getTransactionsForExternal.data 
        && Array.isArray(transaction.getTransactionsForExternal.data)
        && transaction.getTransactionsForExternal.data.length > 0) {
        setCurrencyCode(transaction.getTransactionsForExternal.data[0].currencyCode)
      }
  }, [transaction]);

  const tenderReducer = (state, action) => {
    switch (action.type) {
      case 'REMOVE':
        let updated =state.filter(s =>  !( s.id === action.tender.id && s.type === action.tender.type));
        if(updated.length !== state.length)
          return updated;
        return state;
      case 'ADD_UPDATE':
        let foundIndex = state.findIndex(s => s.id === action.tender.id && s.type === action.tender.type);
        if (foundIndex > -1) {
          if( state[foundIndex].amount !== action.tender.amount
            || state[foundIndex].reason !== action.tender.reason            
          ) {
            return [
              ...state.slice(0, foundIndex),
              action.tender,
              ...state.slice(foundIndex + 1)
            ];
          }else{
            return state;
          }
        }
        else {
          return [...state, action.tender];
        }
      default:
        return state;
    }
  };

  const initialTenders = isArrayWithLength(tenders)
    ? tenders.map((tender) => { 
        const alreadyRefunded = tender.refunds.reduce((a,b) => a + (b['refundAmount'] || 0), 0);
        const amountToRefund = (tender.tenderAmount - alreadyRefunded) > remainingToRefund ? remainingToRefund : (tender.tenderAmount - alreadyRefunded);
        remainingToRefund = remainingToRefund - amountToRefund;
        const minimum = 0.0001;
        return {
          id: tender.id,
          type: tender.type,
          amount: amountToRefund < minimum ? 0 : amountToRefund,
          tender: tender,
          reason: '',
          isChecked: amountToRefund > minimum
        }
      }) 
    : [];

  const [refundTenders, dispatchTenders] = useReducer(tenderReducer, initialTenders);
  
  const setTendersToRefund = (tender, remove) => {
    dispatchTenders({type: remove ? 'REMOVE' : 'ADD_UPDATE', tender});
  };

  const processRefund = useCallback(() => {
    if (refundTenders.length > 0) {
      let mapped = refundTenders.map(t => { 
        return {
          tenderId: t.type === 'in-person' ? '' : t.id, 
          paymentId: t.type === 'in-person' ? t.id : '',
          amount: t.amount * 100,
          reason: t.reason
        }; 
      });
      requestRefund(mapped, getExternalIdTypeName(type), queryParams.refundKey).then((data) => {
        if (data.failedReturns && Object.keys(data.failedReturns).length > 0) {
          const failedReturns = [];
          for (var failed in data.failedReturns){
            if (data.failedReturns.hasOwnProperty(failed)) {
              toastr.error('Error: Request Refund', data.failedReturns[failed]);
              
              const [type, id] = failed.split(',');
              
              failedReturns.push(refundTenders.find(t => t.id === id && t.type === type));
            }
          }
          setRefetchTransactions(true);
        }
        else {
          setSuccess(true);
        }
      }).catch((e) => {
        toastr.error('Error: Request Refund', 'Unable to mark selected transactions to be refunded');
      });
    }
  }, [refundTenders, requestRefund, type]);

  // External trap only
  const handleCompleteModalClose = () => {
    setRefetchTransactions(true);
    setSuccess(false);
  };

  return (
    <StyledContentWrapper>
      <Row>
        <Col>
          <StyledHeaderH2>Refund Request</StyledHeaderH2>
        </Col>
      </Row> 
      <Row>
        <Col xs={12} md={8}>
          <Card>
            <CardHeader>Transactions available for refunds</CardHeader>
            <CardBody>
              {
                transaction.getTransactionsForExternal.isLoading 
                  ? <PageLoader />
                  : isArrayWithLength(initialTenders)
                    ? initialTenders.map((tender) => { 
                        return (
                          <Tender 
                            key={tender.id}
                            tender={tender.tender} 
                            amount={tender.amount}
                            setTendersToRefund={setTendersToRefund}
                            currencyCode={currencyCode}
                            startChecked={tender.isChecked}/>)
                      })
                    : <span>No transactions found</span>
              }
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={4}>      
          <Card>
            <CardHeader>Refund Summary</CardHeader>
            <CardBody>
              <OrderSummary
                label='Available'
                value={(
                  <FormattedNumber
                    currency={currencyCode}
                    {...numberFormatOptions}
                    value={(tenderTotal - refundedAmount) / 100}
                  />
                )} 
              />
              <OrderSummary 
                label='Amount Selected' 
                value={(
                  <FormattedNumber
                    currency={currencyCode}
                    {...numberFormatOptions}
                    value={refundTenders.reduce((a, b) => a + (b['amount'] || 0), 0)}
                  />
                )}
              />
              <Button 
                primary 
                onClick={processRefund}
                style={{ marginLeft: '10px' }}  
                disabled={refundTenders.length <= 0}
              >
                Request Refund
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {success && <TranCompleteModal onModalClose={handleCompleteModalClose} />}
    </StyledContentWrapper>
  )
}

const mapStateToProps = state => {
  return {
    transaction: state.transaction
  }
};

const mapDispatchToProps = dispatch => ({
  requestRefund: (requestedRefunds, type, refundKey) => dispatch(refundActions.requestRefund(requestedRefunds, type, refundKey)),
  getTransactions: (externalType, externalId) => dispatch(transactionActions.getTransactionsForExternal(externalType, externalId, true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Refund);