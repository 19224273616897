import * as types from './types';

const success = (type) =>{
  return `${type}_SUCCESS`;
}
const error = (type) =>{
  return `${type}_ERROR`;
}


const initialState = {
  listLocations: {    
    isLoading: false,
    isError: false
  },
  locations: [],
  devices: [],  
  createDevice: {
    isLoading: false,
    isError: false
  },
  createdDevice: {},
  getDeviceCode: {
    isLoading: false,
    isError: false
  },
  deviceCode: {},
  getDeviceStatus:{
    isLoading: false,
    isError: false    
  },
  deviceStatus: {}
};

export const reducer = (state, action) =>{
  state = state || initialState;
  
  switch (action.type){
    case types.LIST_LOCATIONS:
      return {
        ...state,
        listLocations: {
          isLoading: true,
          isError: false
        }
      };
    case success(types.LIST_LOCATIONS):
      return {
        ...state,
        listLocations: {
          isLoading: false,
          isError: false
        },
        locations: action.payload.data.locations,
        devices: action.payload.data.squareDevices
      };
    case error(types.LIST_LOCATIONS):
      return {
        ...state,
        listLocations: {
          isLoading: false,
          isError: true
        }
      };

    case types.CREATE_DEVICE:
      return {
        ...state,
        createDevice: {
          isLoading: true,
          isError: false
        }
      };
    case success(types.CREATE_DEVICE):
      return {
        ...state,
        createDevice: {
          isLoading: false,
          isError: false
        },
        createdDevice: action.payload.data
      };
    case error(types.CREATE_DEVICE):
      return {
        ...state,
        createDevice: {
          isLoading: false,
          isError: true
        }
      };

    case types.UPDATE_DEVICE:
      return {
        ...state,
        updateDevice: {
          isLoading: true,
          isError: false
        }
      };
    case success(types.UPDATE_DEVICE):
      return {
        ...state,
        updateDevice: {
          isLoading: false,
          isError: false
        },
      };
    case error(types.UPDATE_DEVICE):
      return {
        ...state,
        updateDevice: {
          isLoading: false,
          isError: true
        }
      };

    case types.GET_DEVICE_CODE:
      return {
        ...state,
        getDeviceCode: {
          isLoading: true,
          isError: false
        }
      };
    case success(types.GET_DEVICE_CODE):
      return {
        ...state,
        getDeviceCode: {
          isLoading: false,
          isError: false
        },
        deviceCode: action.payload.data.code,
        devices: [...state.devices, action.payload.data.code],
        locations: state.locations ? state.locations.map((loc)=>{
          if(loc.terminals) {
            loc.terminals = loc.terminals.map((term) =>{
              if(term.id === action.payload.data.terminal.id){
                return action.payload.data.terminal;
              }
              return term;
            });
          }          
          return loc;
        }) : state.locations
      };
    case error(types.GET_DEVICE_CODE):
      return {
        ...state,
        getDeviceCode: {
          isLoading: false,
          isError: true
        }
      };

    case types.GET_DEVICE_STATUS:
      return {
        ...state,
        getDeviceStatus: {
          isLoading: true,
          isError: false
        }
      };
    case success(types.GET_DEVICE_STATUS):
      return {
        ...state,
        getDeviceStatus: {
          isLoading: false,
          isError: false
        },
        deviceStatus: action.payload.data,
        devices: state.devices ? state.devices.map(item =>{
          if(item.id === action.payload.data.id){
            return action.payload.data;
          }
          return item;
        }) : state.devices
      };
    case error(types.GET_DEVICE_STATUS):
      return {
        ...state,
        getDeviceStatus: {
          isLoading: false,
          isError: true
        }
      };
      
    default:
      return state;
  }
  
  
  
}