import React, { Fragment, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useInput, Button, Col, Dropdown, InputGroup, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Row, TextInput } from '@met/react-components';

const checkoutStatuses = [
  { label: 'PENDING', value: 'PENDING'},
  { label: 'INPROGRESS', value: 'INPROGRESS'},
  { label: 'CANCELED', value: 'CANCELED'},
  { label: 'COMPLETED', value: 'COMPLETED'},
];  

const cardBrands = [
  { label: 'VISA', value: 'VISA'},
  { label: 'MASTERCARD', value: 'MASTERCARD'},
  { label: 'AMERICANEXPRESS', value: 'AMERICANEXPRESS'},
  { label: 'DISCOVER', value: 'DISCOVER'},
  { label: 'DISCOVERDINERS', value: 'DISCOVERDINERS'},
  { label: 'JCB', value: 'JCB'},
  { label: 'CHINAUNIONPAY', value: 'CHINAUNIONPAY'},
  { label: 'SQUAREGIFTCARD', value: 'SQUAREGIFTCARD'}
];

const SimulateCheckoutDialog = React.memo(({ hidden, toggle, postSimulation, isLoading, checkoutAmount, transactionId }) =>{
  const expYear = useInput(new Date().getFullYear() + 1);
  const expMonth = useInput(new Date().getMonth());
  const amount = useInput(checkoutAmount);
  const cardLast4 = useInput(null);
  const [status, setStatus] = useState(null);
  const [brand, setBrand] = useState(null);
  
  const handleSimulateClick = ()=>{ 
    postSimulation({
      checkoutStatusEnum: status ? status.value : null,
      amount: amount ? amount.value * 100 : null,
      cardLast4: cardLast4 ? cardLast4.value: null,
      expYear: expYear ? expYear.value : null,
      expMonth: expMonth ? expMonth.value : null,
      cardBrand: brand ? brand.value : null,
      transactionId: transactionId
    })
    .then(() => toggle())
    .catch(()=> toastr.error('Error: Simulating Checkout', 'Sorry'));    
  };

  return (
    <Modal centered hidden={hidden} toggle={toggle} >
      <ModalContent>
        <ModalHeader toggle={toggle}>
          Simulate Checkout
        </ModalHeader>
        <ModalBody style={{'minHeight': '225px'}}>
          <Row>
            <Col>
              <InputGroup>
                <Dropdown
                  value={status}
                  options={checkoutStatuses}
                  placeholder={'Select Checkout Status..'}
                  onChange={setStatus}
                />
              </InputGroup>
            </Col>
          </Row>    
          {
            (status && status.value === 'COMPLETED') && (
              <Fragment>
                <Row>
                  <Col>
                    <InputGroup>
                      <TextInput
                        placeholder='Exp Month'
                        {...expMonth.bindToInput}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputGroup>
                      <TextInput
                        placeholder='Exp Year'
                        {...expYear.bindToInput}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputGroup>
                      <TextInput
                        placeholder='Last 4'
                        {...cardLast4.bindToInput}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputGroup>
                      <Dropdown
                        value={brand}
                        options={cardBrands}
                        placeholder={'Select Card Brand..'}
                        onChange={setBrand}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputGroup>
                      <TextInput
                        placeholder='Amount'
                        {...amount.bindToInput}
                      />
                    </InputGroup>
                  </Col>
                </Row>            
              </Fragment>
            )
          }
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle}>Cancel</Button>
          <Button primary onClick={handleSimulateClick} loading={isLoading}>Simulate</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export default SimulateCheckoutDialog;