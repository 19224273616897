import styled from 'styled-components';
import { fadeIn, rubberBand } from './animation';
import { Alert, Button, Row, Grid } from '@met/react-components';

const StyledAlert = styled(Alert)`
  font-size: 3em;
  font-weight: 600;
  text-align: center;
  animation: ${rubberBand} 1s;
  margin: 3.5em .5em auto .5em;

  svg {
    font-size: 3.25rem;
    margin-right: .25rem;
  }
  
  @media (max-width: 550px) {
    font-size: 2.15em;

    svg {
      font-size: 2.4rem;
    }
  }
`;

const StyledContentWrapper = styled(Grid)`
  opacity: 0;
  margin-bottom: 2rem;
  animation: ${fadeIn} ease-in-out .35s both;

  @media (max-width: 550px) {
    padding-right: .75rem;
    padding-left: .75rem;
  }

  & > div {
    &:first-of-type {
      margin: 1rem auto;
    }
    &:not(:first-of-type) {
      margin-top: .25rem !important;
    }
  }
`;

const StyledGrid = styled(Grid)`
  @media (max-width: 550px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const StyledRow = styled(Row)`
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  & > div:first-of-type {
    @media (min-width: 992px) {
      width: 63%;
      max-width: 63%;
    }
  }
`;

const StyledHeaderH2 = styled.h2`
  margin-bottom: .25rem;
  font-weight: 600;
  line-height: 1.2;
  font-size: 2rem;
  margin-top: 0;
`;

const StyledFormFeedback = styled.span`
  width: 100%;
  height: 100%;
  margin-top: 0.25rem;
  font-size: 85%;
  color: #db011c;
  display: block;
  animation: ${fadeIn} ease-in-out .35s both;
`;

const StyledHR = styled.hr`
  margin-top: .37em;
  margin-bottom: .35em;
  height: 1px;
  border: none;
  display: block;
  background-color: #ececec;
`;

export {
  StyledAlert,
  StyledHeaderH2,
  StyledHR,
  StyledRow,
  StyledGrid,
  StyledFormFeedback,
  StyledContentWrapper,
};