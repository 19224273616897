import * as types from './types';

const initialState = {  
  getSquareRequests: {
    isLoading: false,
    isError: false,
    data: []
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch(action.type) {
    case types.GET_SQUARE_REQUESTS:
      return {
        ...state,
        getSquareRequests: {
          ...state.getSquareRequests,
          isLoading: true,
          isError: false
        }
      };
    case types.GET_SQUARE_REQUESTS_SUCCESS:
      return {
        ...state,
        getSquareRequests: {
          isLoading: false,
          isError: false,
          data: action.payload || []
        }
      };
    case types.GET_SQUARE_REQUESTS_ERROR:
      return {
        ...state,
        getSquareRequests: {
          ...state.getSquareRequests,
          isLoading: false,
          isError: true
        }
      }
    default:
      return state;
  }
};