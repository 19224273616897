import React, { useEffect } from 'react';
import InviteToPay from '../InviteToPay';
import appInsights from '../../appinsights';
import { Row } from '@met/react-components';
import PaymentMethod from '../PaymentMethod';
import SendToTerminal from '../SendToTerminal';
import SquareTransactions from '../SquareTransactions';
import { StyledContentWrapper, StyledHeaderH2 } from '../../shared/styled';
import { useMsal } from "@azure/msal-react";

const Home = () => {
  const { accounts } = useMsal();
  const isUsingMsal = accounts && accounts.length > 0;

  useEffect(() => {
    appInsights.trackPageView({
      name: 'collect',
      url: window.location.href
    });
  }, []);

  return (
    <StyledContentWrapper>
      <Row>
        <StyledHeaderH2>
          Payment Request
        </StyledHeaderH2>
      </Row>
      <Row>
        <PaymentMethod />
      </Row>
      <Row>
        <InviteToPay />
      </Row>
      {!isUsingMsal && <Row>
        <SendToTerminal />
      </Row>}
      <Row>
        <SquareTransactions />
      </Row>
    </StyledContentWrapper>
  );
};

export default Home;
