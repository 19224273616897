import * as types from './types';
import createFetchAction from '../createFetchAction';

export const actionCreators = {
  listLocations: ()=>{
    return createFetchAction({
      method:"GET",
      url: '/api/v1/location/list',
      startAction: types.LIST_LOCATIONS
    });
  },
  createDevice: (device)=>{
    return createFetchAction({
      method: "POST",
      url: '/api/v1/location/devices',
      body:device,
      startAction: types.CREATE_DEVICE,
      ignore404: true
    });
  },
  updateDevice: (device)=>{
    return createFetchAction({
      method: "PATCH",
      url: `/api/v1/location/devices/${device.id}`,
      body:device,
      startAction: types.UPDATE_DEVICE,
      ignore404: true
    });
  },
  getDeviceCode: (id)=>{
    return createFetchAction({
      method: "PATCH",
      url: `/api/v1/location/devices/${id}/deviceCode`,
      startAction: types.GET_DEVICE_CODE,
      ignore404: true,
      actionPayload: {
        id
      }
    });
  },
  getDeviceStatus: (id)=>{
    return createFetchAction({
      method: "GET",
      url: `/api/v1/location/devices/${id}/status`,
      startAction: types.GET_DEVICE_STATUS,
      ignore404: true,
      actionPayload: {
        id
      }
    });
  }
}