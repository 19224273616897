import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyledHR } from '../../shared/styled'
import { StyledLabel, StyledValue, StyledContainer } from './styled';

const OrderSummary = ({ label, value, separator }) => (
  !value ? null : (
    <Fragment>
      <StyledContainer>
        <StyledLabel>{label}</StyledLabel>
        <StyledValue>{value}</StyledValue>
      </StyledContainer>
      {separator && <StyledHR />}
    </Fragment>
  )
);

OrderSummary.propTypes = {
  separator: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string, 
    PropTypes.number,
    PropTypes.element,
  ]),
};

export default OrderSummary;