import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ToggleIcon from './ToggleIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExpandoPanelWrapper, PanelHeader, PanelContent, Title, TitleText, Actions } from './styled'

const ExpandoPanel = ({ title, titleIcon, startExpanded, hide, children }) => {
  const [isActive, setIsActive] = useState(!!startExpanded);

  const handleToggleEvent = (e) => {
    e.preventDefault();
    setIsActive(!isActive);
  };

  return (
    <ExpandoPanelWrapper 
      active={isActive} 
      hide={hide}
    >
      <PanelHeader 
        active={isActive} 
        onClick={(e) => handleToggleEvent(e)}
      >
        <Title>
          {titleIcon && <FontAwesomeIcon icon={titleIcon} />  }
          <TitleText>
            {title}
          </TitleText>
        </Title>
        <Actions>
          <ToggleIcon active={isActive} />
        </Actions>
      </PanelHeader>
      <PanelContent>
        {children}
      </PanelContent>
    </ExpandoPanelWrapper>
  );
};

ExpandoPanel.propTypes = {
  title: PropTypes.string,
  titleIcon: PropTypes.string,
  hide: PropTypes.bool,
  startExpanded: PropTypes.bool,
  children: PropTypes.any,
};

ExpandoPanel.defaultProps = {
  startExpanded: false,
};

export default ExpandoPanel;