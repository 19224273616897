import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useParsedQueryString } from '../../hooks';
import { isArrayWithLength } from '../../helpers';
import ExpandoPanel from '../../components/ExpandoPanel';
import TranCompleteModal from '../../components/TranComplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InviteToPayHelpModal, PreviousInvites } from './components';
import { paymentMethodActions, inviteToPayActions } from '../../store';
import { StyledHelpIcon, StyledAppendIconContainer, StyledPreviousInviteWrapper } from './styled';
import { isValidEmail, getExternalIdKVP, getTransactionTypeId, getExternalId, getExternalSystemId } from '../../helpers';
import { StyledGrid, StyledRow, StyledFormFeedback } from '../../shared/styled';
import { useInput, useDebounce, useToggle, Card, Col, CardBody, CardHeader, InputGroup, TextInput, InputGroupAppend, Button } from '@met/react-components';

const InviteToPay = ({ isLoading, inviteToPay, getPreviousInvites, previousInvites, deleteInviteToPay }) => {
  const { queryParams, isParsed } = useParsedQueryString();

  const emailInput = useInput('');
  const [success, setSuccess] = useState(false);
  const [isOpen, toggleModal] = useToggle(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [canSendInvite, setCanSendInvite] = useState(true);
  const [refreshPreviousInvites, setRefreshPreviousInvites] = useState(true);
  const debouncedEmailValue = useDebounce(emailInput.value, 1250);
  const externalSystemId = getExternalSystemId(queryParams);

  const hidePrevInvitePanel = !isArrayWithLength(previousInvites.data);
  const prevInvitePanelTitleMsg = `Previously Sent Invitations (${hidePrevInvitePanel ? 0 : previousInvites.data.length})`;

  useEffect(() => {
    setIsEmailInvalid(debouncedEmailValue ? !isValidEmail(debouncedEmailValue) : false);
  }, [debouncedEmailValue]);

  useEffect(() => {
    setCanSendInvite(previousInvites.data.length === 0);
  }, [previousInvites.data])

  useEffect(() => {
    if (isParsed && refreshPreviousInvites) {
      const [externalIdType, externalId] = getExternalId(queryParams);
      getPreviousInvites(externalIdType, externalId).then((data) => {
        setRefreshPreviousInvites(false);
      }).catch((e) => {
        toastr.error('Error: Invite To Pay', `Could not retrieve previous invitations.`);
      });
    }
  }, [isParsed, queryParams, refreshPreviousInvites]);

  const handleInviteToPayClick = () => {
    if (!canSendInvite) {
      return;
    }

    if (!isValidEmail(emailInput.value)) {
      setIsEmailInvalid(true);
      return;
    }

    const externalIds = getExternalIdKVP(queryParams);
    const transactionType = getTransactionTypeId(queryParams);
    const { amount, locationId, currencyCode, accountNumber } = queryParams;

    if (transactionType < 0) {
      toastr.error('Error: Invite To Pay', `Unsupported transaction type: ${queryParams.type}.`);
      return;
    }

    inviteToPay(transactionType, emailInput.value, amount, locationId, currencyCode, accountNumber, externalIds, externalSystemId)
      .then((data) => {
        if (!data.successful) { return Promise.reject(data); }
        else { setSuccess(true); }
      })
      .catch((e) => toastr.error('Error: Invite To Pay', `Invitation to pay could not be sent to ${emailInput.value}.`));
  };

  const handleDeleteInvite = useCallback((id) => {
    deleteInviteToPay(id).then((data) => {
      setRefreshPreviousInvites(true);
    }).catch((e) => {
      toastr.error('Error: Invite To Pay', 'Unable to delete invite to pay.');
    });
  }, [deleteInviteToPay]);

  // External trap only
  const handleCompleteModalClose = () => {
    emailInput.clear();
    setRefreshPreviousInvites(true);
    setSuccess(false);
  };

  return (
    <Col>
      <Card>
        <CardHeader>Invite To Pay</CardHeader>
        <CardBody>
          <StyledGrid fluid>
            <StyledRow>
              <InputGroup>
                <TextInput
                  invalid={isEmailInvalid}
                  placeholder='Recipient Email'
                  {...emailInput.bindToInput}
                  disabled={!canSendInvite}
                />
                <InputGroupAppend>
                  <StyledAppendIconContainer>
                    <StyledHelpIcon>
                      <FontAwesomeIcon
                        onClick={toggleModal}
                        icon={isEmailInvalid ? 'times' : 'question-circle'}
                      />
                    </StyledHelpIcon>
                  </StyledAppendIconContainer>
                  <Button
                    secondary
                    loading={isLoading}
                    disabled={!emailInput.hasValue}
                    onClick={handleInviteToPayClick}
                  >
                    <FontAwesomeIcon icon='envelope' /> Invite
                  </Button>
                </InputGroupAppend>
              </InputGroup>
              {
                isEmailInvalid && (
                  <StyledFormFeedback>
                    The value entered is not a valid email
                  </StyledFormFeedback>
                )
              }
              {
                !canSendInvite && (
                  <StyledFormFeedback>
                    Existing Invite to Pay must be deleted prior to new one being sent
                  </StyledFormFeedback>
                )
              }
            </StyledRow>
            <StyledPreviousInviteWrapper hide={hidePrevInvitePanel}>
              <ExpandoPanel
                titleIcon='history'
                startExpanded={true}
                hide={hidePrevInvitePanel}
                title={prevInvitePanelTitleMsg}
              >
                <PreviousInvites
                  previousInvites={previousInvites.data}
                  deleteInvite={handleDeleteInvite}
                />
              </ExpandoPanel>
            </StyledPreviousInviteWrapper>
          </StyledGrid>
        </CardBody>
      </Card>
      <InviteToPayHelpModal show={isOpen} toggle={toggleModal} externalSystemId={externalSystemId} />
      {success && <TranCompleteModal onModalClose={handleCompleteModalClose} />}
    </Col>
  );
};

// Access to state
const mapStateToProps = state => {
  return {
    isLoading: state.paymentMethod.creditCard.inviteToPay.isLoading,
    previousInvites: state.inviteToPay.previousInvites,

  }
};

// What actions are exposed on the component
const mapDispatchToProps = dispatch => ({
  inviteToPay: (transactionType, email, amount, locationId, currencyCode, accountNumber, externalIds, externalSystem) => dispatch(paymentMethodActions.inviteToPay(transactionType, email, amount, locationId, currencyCode, accountNumber, externalIds, externalSystem)),
  getPreviousInvites: (externalIdType, externalId) => dispatch(inviteToPayActions.getPreviousInvites(externalIdType, externalId)),
  deleteInviteToPay: (inviteToPayId) => dispatch(inviteToPayActions.deleteInviteToPayDetails(inviteToPayId))
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteToPay);