import React from 'react';
import 'moment-timezone';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import { Button, Col } from '@met/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PreviousInviteRow, PreviousInvitesWrapper } from './styled';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { numberFormatOptions, defaultCurrencyCode } from '../../../../shared/constants';

const PreviousInviteRecord = React.memo(({ invite, onDeleteInvite }) => (
  <PreviousInviteRow formRow>
    <Col sm={4} md={4} lg={3}>
      <Moment date={invite.created + 'Z'} fromNow local />
    </Col>
    <Col sm={5} md={5} lg={5}>
      {invite.emailAddress}
    </Col>
    <Col sm={2} md={2} lg={2}>
      <FormattedNumber
        {...numberFormatOptions}
        currency={invite.currencyCode || defaultCurrencyCode}
        value={invite.total ? invite.total / 100 : 0}
      />  
    </Col>
    <Col sm={1} md={1} lg={2}>
      <Button 
        sm
        primary
        outline
        onClick={() => onDeleteInvite(invite.inviteToPayId)}
      >
        <FontAwesomeIcon icon='times' />
      </Button>
    </Col>
  </PreviousInviteRow>
));

const PreviousInvites = ({ previousInvites, deleteInvite }) => (
  <TransitionGroup component={PreviousInvitesWrapper}>
    {
      (previousInvites || []).map((invite) => (
        <CSSTransition 
          key={invite.inviteToPayId} 
          timeout={{ enter: 350, exit: 500 }} 
          classNames='previousInvite'
        >
          <PreviousInviteRecord 
            invite={invite}
            onDeleteInvite={deleteInvite}
          />
        </CSSTransition>
      ))
    }
  </TransitionGroup>
);

PreviousInvites.propTypes = {
  previousInvites: PropTypes.arrayOf(Object),
  deleteInvite: PropTypes.func.isRequired,
};

export default PreviousInvites;