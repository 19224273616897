import * as types from './types';

const initialState = {
  creditCard: {
    cards: [],
    selectedCard: null,
    canSaveCreditCard: false,
    getCards: {
      isLoading: false,
      isError: false,
    },
    addCard: {
      isLoading: false,
      isError: false,
    },
    deleteCard: {
      isLoading: false,
      isError: false,
    },
    inviteToPay: {
      isLoading: false,
      isError: false,
    },
    journalCharge: {
      isLoading: false,
      isError: false,
    },
    preAuthSalesOrder: {
      isLoading: false,
      isError: false,
    },
    preAuthServiceOrder: {
      isLoading: false,
      isError: false,
    },
    checkCanSaveCreditCard: {
      isLoading: false,
      isError: false,
    }
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch(action.type) {
    case types.SET_SELECTED_CARD:
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          selectedCard: action.card,
        }
      };
    case types.GET_CARDS_BY_ACCOUNT_NUMBER:
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          getCards: {
            isLoading: true,
            isError: false
          }
        }
      };
    case types.GET_CARDS_BY_ACCOUNT_NUMBER_SUCCESS: 
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          cards: action.payload || [],
          getCards: {
            isLoading: false,
            isError: false,
          }
        }
      };
    case types.GET_CARDS_BY_ACCOUNT_NUMBER_ERROR:
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          getCards: {
            isLoading: false,
            isError: true,
          }
        }
      };
    case types.GET_CAN_SAVE_CREDIT_CARD:
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          checkCanSaveCreditCard: {
            isLoading: true,
            isError: false,
          }
        }
      }
    case types.GET_CAN_SAVE_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          canSaveCreditCard: action.payload,
          checkCanSaveCreditCard: {
            isLoading: false,
            isError: false,
          }
        }
      }
    case types.GET_CAN_SAVE_CREDIT_CARD_ERROR:
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          checkCanSaveCreditCard: {
            isLoading: false,
            isError: true,
          }
        }
      }
    case types.ADD_CARD:
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          addCard: {
            isLoading: true,
            isError: false
          }
        }
      };
    case types.ADD_CARD_SUCCESS:
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          cards: [...state.creditCard.cards, action.payload],
          selectedCard: action.payload,
          addCard: {
            isLoading: false,
            isError: false
          }
        }
      };
    case types.ADD_CARD_ERROR:
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          addCard: {
            isLoading: false,
            isError: true
          }
        }
      };
    case types.DELETE_CARD:
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          deleteCard: {
            isLoading: true,
            isError: false
          }
        }
      };
    case types.DELETE_CARD_SUCCESS:
      const currentSelectedCard = state.creditCard.selectedCard;
      const cardsMinusDeleted = state.creditCard.cards.filter((card) => card.id !== action.id);
      const selectedCardOrNull = (currentSelectedCard && currentSelectedCard.id === action.id) ? null : currentSelectedCard;

      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          selectedCard: selectedCardOrNull,
          cards: cardsMinusDeleted,
          deleteCard: {
            isLoading: false,
            isError: false
          }
        }
      };
    case types.DELETE_CARD_ERROR:
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          deleteCard: {
            isLoading: false,
            isError: true
          }
        }
      };
	  case types.INVITE_TO_PAY:
     return {
        ...state,
        creditCard: {
          ...state.creditCard,
          inviteToPay: {
            isLoading: true,
            isError: false
          }
        }
      };
    case types.INVITE_TO_PAY_SUCCESS:
     return {
        ...state,
        creditCard: {
          ...state.creditCard,
          inviteToPay: {
            isLoading: false,
            isError: false
          }
        }
      };
    case types.INVITE_TO_PAY_ERROR:
     return {
        ...state,
        creditCard: {
          ...state.creditCard,
          inviteToPay: {
            isLoading: false,
            isError: true
          }
        }
      };
    case types.PRE_AUTH_SALES_ORDER:
     return {
        ...state,
        creditCard: {
          ...state.creditCard,
          preAuthSalesOrder: {
            isLoading: true,
            isError: false
          }
        }
      };
    case types.PRE_AUTH_SALES_ORDER_SUCCESS:
     return {
        ...state,
        creditCard: {
          ...state.creditCard,
          preAuthSalesOrder: {
            isLoading: false,
            isError: false
          }
        }
      };
    case types.PRE_AUTH_SALES_ORDER_ERROR:
     return {
        ...state,
        creditCard: {
          ...state.creditCard,
          preAuthSalesOrder: {
            isLoading: false,
            isError: true
          }
        }
      };
    case types.PRE_AUTH_SERVICE_ORDER:
     return {
        ...state,
        creditCard: {
          ...state.creditCard,
          preAuthServiceOrder: {
            isLoading: true,
            isError: false
          }
        }
      };
    case types.PRE_AUTH_SERVICE_ORDER_SUCCESS:
     return {
        ...state,
        creditCard: {
          ...state.creditCard,
          preAuthServiceOrder: {
            isLoading: false,
            isError: false
          }
        }
      };
    case types.PRE_AUTH_SERVICE_ORDER_ERROR:
     return {
        ...state,
        creditCard: {
          ...state.creditCard,
          preAuthServiceOrder: {
            isLoading: false,
            isError: true
          }
        }
      };
    case types.JOURNAL_CHARGE:
     return {
        ...state,
        creditCard: {
          ...state.creditCard,
          journalCharge: {
            isLoading: true,
            isError: false
          }
        }
      };
    case types.JOURNAL_CHARGE_SUCCESS:
     return {
        ...state,
        creditCard: {
          ...state.creditCard,
          journalCharge: {
            isLoading: false,
            isError: false
          }
        }
      };
    case types.JOURNAL_CHARGE_ERROR:
     return {
        ...state,
        creditCard: {
          ...state.creditCard,
          journalCharge: {
            isLoading: false,
            isError: true
          }
        }
      };
    default:
        return state;
    }
};