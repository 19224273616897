import React from 'react';
import { StyledAlert } from '../../shared/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NotFound = () => (
  <StyledAlert warning>
    <FontAwesomeIcon icon='exclamation-circle' /> 404 Not Found
  </StyledAlert>
);

export default NotFound;