import {
  Button,
  LoadingDots,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text
} from "@met/react-components";
import React from "react";

const AccessCodeModal = ({accessCode, isLoading, isError, handleOk, isOpen, checkDeviceStatus, isStatusLoading, isPaired, handleCancel}) =>{
  const buildAccessCode = (code) => {
    if(code?.length){
      let len = code.length
      return `${code.substr(0,len / 2)}-${code.substr((len/2))}`
    }
  }

  
  return (
    <Modal hidden={!isOpen} >
      <ModalContent>
        <ModalHeader>
          Getting New Code
        </ModalHeader>
        <ModalBody>
          {!isLoading && !isError ?
            (
              <React.Fragment>
                <Text block>Device Code</Text>
                <Text block lg>{buildAccessCode(accessCode)}</Text>                
              </React.Fragment>
            )
            : ''}
          {isError ? <Text error block>Error getting device code</Text> : ""}
          {isLoading || isStatusLoading ? <LoadingDots /> : ''}
          {isPaired && <Text success block>Device Paired</Text>}
          {!isPaired && !isStatusLoading && <Text warning block>Device NOT Paired</Text>}
        </ModalBody>
        <ModalFooter>
          {checkDeviceStatus && <Button onClick={checkDeviceStatus} loading={isStatusLoading || isLoading} info>Check Status</Button> }
          <Button onClick={handleOk} loading={isLoading} style={{marginLeft: '5px'}} primary>Ok</Button>
          {handleCancel && <Button onClick={handleCancel} secondary style={{marginLeft: '5px'}} loading={isLoading}>Close</Button>}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
};

export default AccessCodeModal;