import React, {Fragment} from 'react';

import Home from './containers/Home';
import Layout from './containers/Layout';
import Locations from "./containers/Locations";
import NewTerminal from "./containers/NewTerminal";
import Refund from './containers/Refund';
import Checkout from './containers/Checkout';
import NotFound from './components/NotFound';
import { Route, Switch } from 'react-router';

const App = () => (
  <Fragment>
    <Layout>
      <Switch>
        <Route exact path='/' component={NotFound} />
        <Route exact path='/Collect' component={Home} />
        <Route exact path='/Checkout' component={Checkout} />   
        <Route exact path='/Refund' component={Refund} />
        <Route exact path='/Admin/Locations' component={Locations} />
        <Route exact path='/Admin/Locations/NewTerminal' component={NewTerminal} />
        <Route component={NotFound} /> 
      </Switch>
    </Layout>
  </Fragment>
);

export default App;