import React from 'react';
import PropTypes from 'prop-types';
import { StyledList, StyledInfoIcon } from './styled';
import { Row, Col, Modal, ModalBody, ModalHeader, ModalContent } from '@met/react-components';
import { getExternalSystemDisplayName } from '../../../../helpers';

const InviteToPayHelpModal = React.memo(({ show, toggle, externalSystemId }) => (
  <Modal hidden={!show} toggle={toggle} centered={true} size={'md'}>
    <ModalContent>
      <ModalHeader toggle={toggle}>
        <StyledInfoIcon icon='info-circle' /> Invite To Pay
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <StyledList>
              <li>
                Enter recipient's email address in the text box
              </li>
              <li>
                Next, click the send button and an email containing instructions and a link to the payment portal will be sent
              </li>
              <li>
                Once the recipient has completed the payment, the transaction will be updated in <b>{getExternalSystemDisplayName(externalSystemId)}</b>
              </li>
            </StyledList>
          </Col>
        </Row>
      </ModalBody>
    </ModalContent>
  </Modal>
));

InviteToPayHelpModal.propTypes = {
  show: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
};

export default InviteToPayHelpModal;