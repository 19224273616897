import styled, { css } from 'styled-components';

const isPanelActive = (props) => {
  if (props.hide) {
    return css`
      height: 0;
      margin: 0;
      opacity: 0;
    `;
  }

  return props.active && css`
    height: auto;
    overflow: auto;
    margin: 16px 0;
  `;
};

const isPanelHeaderActive = (props) => (
  props.active && css`
    border-bottom: 1px solid #ececec;
    background-color: rgba(0,0,0,0.03);
  `
);

const isToggleActive = (props) => (
  props.active &&
  css`
    opacity: 1;
    color: #db011c;
    transform: rotate(180deg);
  `
);

const ToggleIconWrapper = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
  height: 100%;
`;

const ArrowWrapper = styled.div`
  opacity: .75;
  display: flex;
  margin-left: .85rem;
`;

const Arrow = styled.span`
  border-top: 8px dashed;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  transform: rotate(0deg);
  transition: transform .3s ease;
  ${(props) => isToggleActive(props)}
`;

const ExpandoPanelWrapper = styled.div`
  background: #FFF;
  width: 100%;
  height: 48px;
  margin: 0;
  box-shadow: 0 -1px 0 #e5e5e5, 0 0 2px rgba(0,0,0,.075), 0 1px 4px rgba(0,0,0,.1125);
  border-bottom: 1px solid rgba(0,0,0,0.1);
  overflow: hidden;
  position: relative;
  transition: all .3s ease;
  opacity: 1;
  ${(props) => isPanelActive(props)}
`;

const PanelHeader = styled.div`
  height: 48px;
  width: 100%;
  cursor: pointer;
  ${(props) => isPanelHeaderActive(props)}
`;

const Title = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
  height: 100%;
  color: inherit;
  float: left;
  padding: 0 0 0 24px;
  font-size: 1.1rem;

  svg {
    color: #6c757d;
    font-size: 1.15rem;
  }

  @media (max-width: 550px) {
    font-size: .95rem;
    padding: 0 0 0 12px;
    svg {
      font-size: 1rem;
    }
  }
`;

const TitleText = styled.span`
  display: inline-block;
  margin-left: 16px;
`;

const Actions = styled.div`
  height: 100%;
  padding: 0 24px 0 0;
  color: #495057;
  float: right;
`;

const PanelContent = styled.div`
  padding: 16px 24px;
`;

export {
  ToggleIconWrapper,
  ArrowWrapper,
  Arrow,
  ExpandoPanelWrapper,
  PanelHeader,
  PanelContent,
  Title,
  TitleText,
  Actions,
};