export const SET_SELECTED_TERMINAL = 'SET_SELECTED_TERMINAL';

export const SEND_TO_TERMINAL = 'SEND_TO_TERMINAL';
export const SEND_TO_TERMINAL_ERROR = 'SEND_TO_TERMINAL_ERROR';
export const SEND_TO_TERMINAL_SUCCESS = 'SEND_TO_TERMINAL_SUCCESS';

export const GET_TERMINALS_BY_LOCATION_ID = 'GET_TERMINALS_BY_LOCATION_ID';
export const GET_TERMINALS_BY_LOCATION_ID_ERROR = 'GET_TERMINALS_BY_LOCATION_ID_ERROR';
export const GET_TERMINALS_BY_LOCATION_ID_SUCCESS = 'GET_TERMINALS_BY_LOCATION_ID_SUCCESS';

export const GET_CHECKOUT_DETAILS = 'GET_CHECKOUT_DETAILS';
export const GET_CHECKOUT_DETAILS_ERROR = 'GET_CHECKOUT_DETAILS_ERROR';
export const GET_CHECKOUT_DETAILS_SUCCESS = 'GET_CHECKOUT_DETAILS_SUCCESS';

export const REQUEST_ADDITIONAL_CHECKOUT = 'REQUEST_ADDITIONAL_CHECKOUT';
export const REQUEST_ADDITIONAL_CHECKOUT_ERROR = 'REQUEST_ADDITIONAL_CHECKOUT_ERROR';
export const REQUEST_ADDITIONAL_CHECKOUT_SUCCESS = 'REQUEST_ADDITIONAL_CHECKOUT_SUCCESS';

export const VERIFY_CHECKOUT = 'VERIFY_CHECKOUT';
export const VERIFY_CHECKOUT_ERROR = 'VERIFY_CHECKOUT_ERROR';
export const VERIFY_CHECKOUT_SUCCESS = 'VERIFY_CHECKOUT_SUCCESS';

export const SIMULATE_CHECKOUT = 'SIMULATE_CHECKOUT';
export const SIMULATE_CHECKOUT_ERROR = 'SIMULATE_CHECKOUT_ERROR';
export const SIMULATE_CHECKOUT_SUCCESS = 'SIMULATE_CHECKOUT_SUCCESS';

export const POLL_CHECKOUT = 'POLL_CHECKOUT'
export const POLL_CHECKOUT_ERROR = 'POLL_CHECKOUT_ERROR'
export const POLL_CHECKOUT_SUCCESS = 'POLL_CHECKOUT_SUCCESS'
