import createFetchAction from '../createFetchAction';
import * as types from './types';

export const actionCreators = {
  requestRefund: (refunds, type, refundKey) => {
    return createFetchAction({
      method: 'POST',
      url: `/api/v1/refundtransaction/`,
      startAction: types.REQUEST_REFUND,
      body: {
        refunds,
        transactionType: type,
        refundKey: refundKey
      }
    })
  }
};