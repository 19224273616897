import styled from 'styled-components';
import { Col } from '@met/react-components';
import { fadeIn } from '../../shared/styled';

const StyledWrapperCol = styled(Col)`
  opacity: 0;
  animation: ${fadeIn} ease-in-out .35s both;
`;

export {
  StyledWrapperCol
};