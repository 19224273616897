import * as types from './types';
import createFetchAction from '../createFetchAction';

export const actionCreators = {
  getPreviousInvites: (externalIdType, externalId) => {
    return createFetchAction({
      method: 'GET',
      url: `/api/v1/invitetopay/Previous/${externalIdType}/${externalId}`,
      startAction: types.GET_PREVIOUS_INVITES
    });
  },
  deleteInviteToPayDetails: (hashedId) => {
    return createFetchAction({
      method: 'DELETE',
      url: `/api/v1/invitetopay/${hashedId}`,
      startAction: types.DELETE_INVITE,
      actionPayload: {
        inviteToPayId: hashedId,
      }
    })
  }
}