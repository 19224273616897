import {
  library
} from '@fortawesome/fontawesome-svg-core';

import {
  faBan,
  faCheck,
  faHistory,
  faCreditCard,
  faDollarSign,
  faInfoCircle,
  faEnvelope,
  faShare,
  faTimes,
  faTabletAlt,
  faQuestionCircle,
  faExclamationCircle,
  faSync,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faBan,
  faCheck,
  faHistory,
  faCreditCard,
  faDollarSign,
  faShare,
  faInfoCircle,
  faEnvelope,
  faTimes,
  faTabletAlt,
  faQuestionCircle,
  faExclamationCircle,
  faSync,
);