export const getSession = () => {
  const session = JSON.parse(localStorage.getItem('pciportal:session') || '{}');
  return session || {};
};

export const updateSession = (session) => {
  const preexistingSession = JSON.parse(localStorage.getItem('pciportal:session') || '{}');
  localStorage.setItem('pciportal:session', JSON.stringify({...preexistingSession, ...session}));
};

export const clearSession = () => {
  localStorage.removeItem('pciportal:session');
};