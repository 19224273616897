import * as types from './types';

const initialState = {
  refunds: {
    requestRefunds: { 
      isLoading: false,
      isError: false
    }
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch(action.type) {
    case types.REQUEST_REFUND:
      return {
        ...state,
        refunds: {
          ...state.refunds,
          requestRefunds: {
            isLoading: true,
            isError: false
          }
        }
      }
    case types.REQUEST_REFUND_SUCCESS:
      return {
        ...state,
        refunds: {
          ...state.refunds,
          requestRefunds: {
            isLoading: false,
            isError: false
          }
        }
      }
    case types.REQUEST_REFUND_ERROR:
      return {
        ...state,
        refunds: {
          ...state.refunds,
          requestRefunds: {
            isLoading: false,
            isError: true
          }
        }
      }
    default:
      return state;
  }
};