import { useEffect, useState } from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { AuthError } from "@azure/msal-browser";
import { loginRequest, msalInstance, isUsingEntraAuth } from '../msal';

function useEntraAuthentication() {
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const authenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  
  useEffect(() => {
    // If we're in the embedded browser, we'll use jwt auth so we don't need to do anything here
    // Context: we can't call hooks conditionally, so we have to do this
    if (!isUsingEntraAuth) {
      return;
    }

    setIsLoading(true);

    (async () => {
      try {
        await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });
      } catch (error) {
        if (error instanceof AuthError) {
          // fallback to interaction when silent call fails
          return msalInstance.acquireTokenRedirect({
            account: accounts[0],
          });
        }
        setError(true);
        setErrorText('Unknown error.');
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return [authenticated, error, errorText, isLoading];
}

export { useEntraAuthentication };
