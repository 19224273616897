export const isMobileDevice = () => {
  return (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i
    .test(navigator.userAgent || navigator.vendor)
  );
};

export const browserWindowWidth = () => {
  return window.innerWidth || document.documentElement.clientWidth|| document.body.clientWidth;
};

export const isMobileDeviceOrNarrowWindow = () => {
  return browserWindowWidth() < 1025 || isMobileDevice();
}