import styled from 'styled-components';

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  padding: .2rem 0;
  align-items: center;
  justify-content: space-between;
`;

const StyledLabel = styled.span`
  font-size: 1.1rem;
  margin-right: 15px;
`;

const StyledValue = styled.span`
  font-size: 1rem;
  font-weight: 700;
  word-break: break-all;
`;

export {
  StyledLabel,
  StyledValue,
  StyledContainer
};