import { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`;

const fadeInDown  = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  } to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeInLeft  = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  } to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeInRight  = keyframes`
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  } to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const rubberBand = keyframes`
  from {
    transform: scale3d(1, 1, 1);
  } 30% {
    transform: scale3d(1.25, 0.75, 1);
  } 40% {
    transform: scale3d(0.75, 1.25, 1);
  } 50% {
    transform: scale3d(1.15, 0.85, 1);
  } 65% {
    transform: scale3d(0.95, 1.05, 1);
  } 75% {
    transform: scale3d(1.05, 0.95, 1);
  } to {
    transform: scale3d(1, 1, 1);
  }
`;

const heartBeat  = keyframes`
  0% {
    transform: scale(1);
  } 14% {
    transform: scale(1.3);
  } 28% {
    transform: scale(1);
  } 42% {
    transform: scale(1.3);
  } 70% {
    transform: scale(1);
  }
`;

const animateLineTip = keyframes`
  0% {
    background-color: #fff;
    top: 1.1875em;
    left: .0625em;
    width: 0;
  } 54% {
    top: 1.0625em;
    left: .125em;
    width: 0;
  } 70% {
    top: 2.1875em;
    left: -.375em;
    width: 3.125em;
  } 84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  } 100% {
    background-color: #28a745;
    top: 2.8125em;
    left: .875em;
    width: 1.5625em;
  }
`;

const animateLineLong = keyframes`
  0% {
    background-color: #fff;
    top: 3.375em;
    right: 2.875em;
    width: 0;
  } 65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  } 84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  } 100% {
    background-color: #28a745;
    top: 2.375em;
    right: .5em;
    width: 2.9375em;
  }
`;

const animateCircularLine = keyframes`
  0% {
    transform: rotate(-45deg);
  } 5% {
    transform: rotate(-45deg);
  } 12% {
    transform: rotate(-405deg);
  } 100% {
    transform: rotate(-405deg);
  }
`;

const lightSpeedOut = keyframes`
  from {
    opacity: 1;
  } to {
    transform: translate3d(195px, 0, 0) skewX(30deg);
    opacity: 0;
  }
`;

export {
  lightSpeedOut,
  fadeIn,
  fadeInDown,
  fadeInLeft,
  fadeInRight,
  heartBeat,
  rubberBand,
  animateLineTip,
  animateLineLong,
  animateCircularLine,
};