import styled from 'styled-components';
import { ModalFooter } from '@met/react-components';

const StyledMessageHeader = styled.div`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: .25rem;
`;

const StyledMessage = styled.div`
  text-align: center;
  font-size: 1.2rem;
`;

const StyledInfoIconContainer = styled.div`
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: .25em auto .5em;
  zoom: normal;
  border: .25em solid transparent;
  border-radius: 50%;
  line-height: 5em;
  cursor: default;
  user-select: none;
  display: flex;
  border-color: rgba(23, 162, 184, .7);

  :before {
    display: flex;
    align-items: center;
    height: 92%;
    font-size: 3.75em;
    content: 'i';
    color: #17a2b8;
  }
`;

const StyledModalFooter = styled(ModalFooter)`
  display: block;
  border-top: none;
  text-align: center;
  padding: 0rem 1rem 1rem;

  & > button {
    width: 25%;
    margin-left: 0;
    margin-bottom: .25rem;
  }
`;

export {
  StyledMessage,
  StyledMessageHeader,
  StyledModalFooter,
  StyledInfoIconContainer
};