import { useEffect, useState } from 'react';
import appInsights from "../appinsights";
import { getSession, updateSession } from '../helpers';
import { isUsingEntraAuth } from '../msal';

const _tokenExpirationHours = 4;

export const useJwtAuthentication = () => {
  const [state, setState] = useState({
    authenticated: false,
    error: false,
    errorText: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function doTheThings() {
      const isAuthenticated = () => {
        const tokenExpiry = getSession().expiresAt || 0;
        return new Date().getTime() < tokenExpiry;
      };

      // Check if token is still valid, if so, skip authentication/token generation
      if (isAuthenticated()) {
        appInsights.trackTrace({
          message: "User is already authenticated"
        });
        setState({
          authenticated: true,
          error: false,
          errorText: '',
        });
        return;
      }

      if (window.mkeChromeBrowser) {
        appInsights.trackTrace({
          message: "Embedded: Getting user token"
        });
        setIsLoading(true);
        const url = window.location.protocol + '//' + window.location.host;

        try {
          let actualResult = await window.mkeChromeBrowser.getToken(url);

          appInsights.trackTrace({
            message: "Embedded: Got user token"
          });

          try {
            const obj = JSON.parse(actualResult);
            if (obj.successful || obj.Successful) {
              appInsights.trackTrace({
                message: "Embedded: Successful"
              });
              updateSession({
                accessToken: obj.Data,
                expiresAt: JSON.stringify(new Date().getTime() + (_tokenExpirationHours * 60 * 60 * 1000)),
              });
              setIsLoading(false);
              setState({
                authenticated: true,
                error: false,
                errorText: '',
              });
            } else if (obj.responseCode === 401 || obj.ResponseCode === 401) {
              appInsights.trackTrace({
                message: "Embedded: Unauthorized"
              });
              setState({
                authenticated: false,
                error: true,
                errorText: 'Embedded: Unauthorized',
              });
              setIsLoading(false);
            } else if (obj.responseCode === 403 || obj.ResponseCode === 403) {
              appInsights.trackTrace({
                message: "Embedded: Forbidden"
              });
              setState({
                authenticated: false,
                error: true,
                errorText: 'Embedded: Forbidden',
              });
              setIsLoading(false);
            } else if (obj.responseCode === 500 || obj.ResponseCode === 500) {
              appInsights.trackTrace({
                message: "Embedded: Server Exception"
              });
              setState({
                authenticated: false,
                error: true,
                errorText: 'Embedded: Server Exception',
              });
              setIsLoading(false);
            } else {
              let info = "";

              if (obj.ResponseCode || obj.responseCode) {
                info += "Server ResponseCode: " + (obj.ResponseCode || obj.responseCode)
              }

              if (obj.Exception) {
                info += "Client Exception: " + obj.Exception;
              }
              appInsights.trackTrace({
                message: "Embedded: Client Exception: " + info
              });
              setState({
                authenticated: false,
                error: true,
                errorText: 'Embedded: Error: ' + info,
              });
              setIsLoading(false);
            }
          } catch (e) {
            appInsights.trackTrace({
              message: "Embedded: Unknown Error" + e
            });
            appInsights.trackException(e);
            setIsLoading(false);
            setState({
              authenticated: false,
              error: true,
              errorText: 'Embedded: javascript error ' + e,
            });
          }
        } catch (e) {
          appInsights.trackTrace({
            message: "Embedded: error calling getToken on embedded browser" + e
          });
          appInsights.trackException(e);
          setIsLoading(false);
          setState({
            authenticated: false,
            error: true,
            errorText: 'error calling getToken on the embedded browser',
          });
        }
      } else {
        setIsLoading(true);
        fetch('/api/v1/token').then((response) => {
          if (response.ok) {
            return response.text().then((token) => {
              const tokenIsValid = token && token.trim().length > 0;
              if (tokenIsValid) {
                updateSession({
                  accessToken: token,
                  expiresAt: JSON.stringify(new Date().getTime() + (_tokenExpirationHours * 60 * 60 * 1000)),
                });
              }
              setState({
                authenticated: tokenIsValid,
                error: !tokenIsValid,
                errorText: '',
              });
              setIsLoading(false);
            });
          } else if (response.status === 401) {
            setState({
              authenticated: false,
              error: true,
              errorText: 'Unauthorized',
            });
            setIsLoading(false);
          } else if (response.status === 403) {
            setState({
              authenticated: false,
              error: true,
              errorText: 'Forbidden',
            });
            setIsLoading(false);
          } else if (response.status === 500) {
            setState({
              authenticated: false,
              error: true,
              errorText: 'Server Exception',
            });
            setIsLoading(false);
          } else {
            setState({
              authenticated: false,
              error: true,
              errorText: 'Unknown Error: ' + response.statusText,
            });
            setIsLoading(false);
          }
        })
      }
    }

    // If we're in the embedded browser, do the things, otherwise do nothing
    // Context: we can't call hooks conditionally, so we have to do this
    if (!isUsingEntraAuth) {
      doTheThings();
    }
  }, []);

  return [state.authenticated, state.error, state.errorText, isLoading];
};