import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { StyledWrapperCol, TableWrapper } from './styled';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import { squareActions } from '../../store';
import { useParsedQueryString } from '../../hooks';
import { getExternalId } from '../../helpers';
import { isArrayWithLength } from '../../helpers';
import {
  Card,
  CardBody,
  CardHeader,
  Table
} from '@met/react-components';

const SquareTransactions = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { queryParams, isParsed } = useParsedQueryString();
  const transactions = useSelector(state => state.square.getSquareRequests.data);

  let id = 0;
  const transactionsWithId = transactions.map(x => {
    return {
      ...x,
      id: ++id
    }
  });

  useEffect(() => {
    if (isParsed) {
      const [externalIdType, externalId] = getExternalId(queryParams);
      dispatch(squareActions.getSquareRequests(externalIdType, externalId)).catch((e) => {
        toastr.error('Error: Transaction History', `Could not retrieve transaction history.`);
      }).finally(() => {
        setIsLoading(false);
      });

    }
  }, [isParsed, queryParams]);

  const columns = {
    callType: {
      label: 'Type'
    },
    externalId: {
      label: 'External Id'
    },
    request: {
      label: 'Request'
    },
    status: {
      label: 'Status'
    },
    time: {
      label: 'Time',
      content: (item) => moment.utc(item.time).local().format("MM/DD/YYYY h:mm:ss a")
    }
  }

  return !isLoading && isArrayWithLength(transactions) && (
    <StyledWrapperCol>
      <Card>
        <CardHeader>Square Requests</CardHeader>
        <CardBody>
          <TableWrapper>
            <Table
              striped
              columns={columns}
              data={transactionsWithId}
            />
          </TableWrapper>
        </CardBody>
      </Card>
    </StyledWrapperCol>
  );
};

export default SquareTransactions;