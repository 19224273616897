import styled from 'styled-components';

const StyledNavbar = styled.nav`
  font-size: 18px;
  position: fixed;
  width: 100%
  height: 84px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1030;
  background-color: #db011c;
`;

const StyledLogo = styled.div`
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
  font-size: 22px;
  margin-left: 20px;
  align-items: center;
  display: flex;
  
  @media (min-width: 768px) {
    margin-top: 0;
  };
`;

export {
  StyledLogo,
  StyledNavbar
};