import styled from 'styled-components';

const StyledTableWrapper = styled.div`
  min-height: 122px;

  table {
    @media (max-width: 768px) {
      & > tbody > tr > td {
        &:not(:last-of-type) {
          padding: 0px 0px 12px 43%;
        }
  
        &:last-of-type {
          padding: 0px 0px 0px 43%;
        }
      }
    }

    & > thead > tr > th {
      font-weight: 600;
    }

    & > tbody > tr > td {
      font-size: .95rem;

      &:before {
        font-size: .9em;
      }
    }
  }
`;

export {
  StyledTableWrapper,
};