import * as types from './types';
import { isMobileDeviceOrNarrowWindow } from '../../helpers';

const initialState = {
  isLoading: false,
  isError: false,
  isLoaded: false,
  data: {},
  isMobileDevice: isMobileDeviceOrNarrowWindow(),
};

export const reducer = (state, action) =>{
  state = state || initialState;

  switch (action.type) {
    case types.GET_CONFIGURATION:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        isError: false,
        isMobileDevice: isMobileDeviceOrNarrowWindow(),
      };
    case types.GET_CONFIGURATION_ERROR:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        isError: true 
      };
    case types.GET_CONFIGURATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isLoaded: true,
        data: action.payload || {}
      };
    default:
      return state;
  }
};