import styled from 'styled-components';
import { Row } from '@met/react-components';
import { fadeIn } from '../../shared/styled';

const StyledButtonRow = styled(Row)`
  margin-left: 0rem;
  margin-right: 0rem;

  & > button {
    animation: ${fadeIn} .35s both ease-in;
    
    @media (max-width: 770px) {
      display: block;
      width: 100%;
    }
    
    &:not(:first-of-type) {
      margin-left: 10px;

      @media (max-width: 770px) {
        margin-left: 0px;
        margin-top: 10px;
      }
    }
  }
`;

export {
  StyledButtonRow
};