import { useEffect, useState } from 'react';
import { HubConnectionBuilder } from '@aspnet/signalr';

export const useSignalR = (endpoint, userId) => {
  const [hub, setHub] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  // Create the hub on initial mount
  useEffect(() => {
    const initializeHub = () => {
      const hubConnection =  new HubConnectionBuilder()
        .withUrl(endpoint, {
          accessTokenFactory: () => {
            return userId;
          },
          httpClient: {
            getCookieString: (url) => '',
            post: (url, httpOptions) => {
              return fetch(url,  {
                method: 'POST',
                headers: {
                  ...httpOptions.headers,
                  UserId: userId,
                  'Content-Type': 'application/json'
                }
              }).then(response => {
                return response.text().then(txt=>{
                  return {
                    statusCode: response.status,
                    statusText: response.statusText,
                    content: txt
                  }
                })
              });
            }
          }
        })
        .build();

      setHub(hubConnection);
    };

    if(userId && endpoint) {
      initializeHub();
    }
  }, [endpoint, userId]);

  // After the hub is created, start it.
  // When the component unmounts, stop the hub so we don't keep sockets open forever.
  useEffect(() => {
    if (hub) {
      hub.start()
        .then(() => setIsConnected(true))
        .catch(() => console.log('Error while connecting to hub!'));
    }

    return () => {
      if (hub) {
        hub.stop();
      }
    };
  }, [hub]);

  return [hub, isConnected];
};