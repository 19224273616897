import React from 'react';
import styled from 'styled-components';
import { ModalFooter } from '@met/react-components';
import { heartBeat, animateLineTip, animateLineLong, animateCircularLine } from '../../shared/styled';

const SuccessIconContainer = styled.div`
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: .25em auto 1.15em;
  zoom: normal;
  border: .25em solid transparent;
  border-radius: 50%;
  line-height: 5em;
  cursor: default;
  user-select: none;
  display: flex;
  border-color: #28a745;

  :before {
    display: flex;
    align-items: center;
    height: 92%;
    font-size: 3.75em;
  }
`;

const SuccessIconCircleLineLeft = styled.div`
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  transform: rotate(45deg);
  border-radius: 50%;
  top: -.4375em;
  left: -2.0635em;
  transform: rotate(-45deg);
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em;
  background-color: rgb(255, 255, 255);
`;

const SuccessIconCircleLineRight = styled.div`
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  transform: rotate(45deg);
  border-radius: 50%;
  top: -.6875em;
  left: 1.875em;
  transform: rotate(-45deg);
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0;
  background-color: rgb(255, 255, 255);
  animation-name: ${animateCircularLine};
  animation-timing-function: ease-in;  
  animation-duration: 4.25s;  
  animation-delay: .2s;
`;

const SuccessIconFix = styled.div`
  position: absolute;
  z-index: 1;
  top: .5em;
  left: 1.625em;
  width: .4375em;
  height: 5.625em;
  transform: rotate(-45deg);
  background-color: rgb(255, 255, 255);
`;

const SuccessIconLineTip = styled.span`
  display: block;
  position: absolute;
  z-index: 2;
  height: .3125em;
  border-radius: .125em;
  background-color: #fff;
  top: 2.875em;
  left: .875em;
  width: 1.5625em;
  transform: rotate(45deg);
  animation-name: ${animateLineTip};
  animation-duration: .75s;  
  animation-delay: .2s;
  animation-fill-mode: forwards;
`;

const SuccessIconLineLong = styled.span`
  display: block;
  position: absolute;
  z-index: 2;
  height: .3125em;
  border-radius: .125em;
  background-color: #fff;
  top: 2.375em;
  right: .5em;
  width: 2.9375em;
  transform: rotate(-45deg);
  animation-name: ${animateLineLong};
  animation-duration: .75s;  
  animation-delay: .2s;
  animation-fill-mode: forwards;
`;

const SuccessIconRing = styled.div`
  position: absolute;
  z-index: 2;
  top: -.25em;
  left: -.25em;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: .25em solid rgba(40, 167, 69, .35);
  border-radius: 50%;
`;

const StyledMessageHeader = styled.div`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: .25rem;
`;

const StyledMessageContainer = styled.div`
  text-align: center;
  font-size: 1.2rem;
`;

const StyledMessage = styled.div`
  display: block;
  position: relative;

  &.heartBeat {
    animation-name: ${heartBeat};
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
  }
`;

const StyledModalFooter = styled(ModalFooter)`
  display: block;
  border-top: none;
  text-align: center;
  padding: 0rem 1rem 1rem;

  & > button {
    width: 25%;
    margin-left: 0;
    margin-bottom: .25rem;
  }
`;

const AnimatedSuccessIcon = () => (
  <SuccessIconContainer>
    <SuccessIconCircleLineLeft />
    <SuccessIconLineTip />
    <SuccessIconLineLong />
    <SuccessIconRing />
    <SuccessIconFix />
    <SuccessIconCircleLineRight />
  </SuccessIconContainer>
);

export {
  StyledMessage,
  StyledMessageHeader,
  StyledMessageContainer,
  StyledModalFooter,
  AnimatedSuccessIcon,
};